import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../../../shared/imgs/klogo.png";
import "./AssetDetails.css";
import {
  toggleModalAction,
} from "../../../../store/actions/actions";
import { bindActionCreators } from "redux";
import { Table, NoItems } from "@kehillahglobal/ui";
import Title from '../../../../components/title/Title';
import {
  capitalizeMe,
  getFileType,
  getMeaningfulDate,
  parseJSON,
} from "../../../../shared/js/utils";
import BackButton from "../../../../shared/components/back-button/BackButton";


class AssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showActions: false,
      id: "",
      asset: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { dealID, assetID } = props?.match?.params;
    if (dealID) {
      let partnership = props?.deals?.find((item) => item?.deal.id === dealID);
      let asset = partnership?.deal?.assets?.find(
        (asset) => asset.id === assetID
      );
      return {
        asset,
      };
    }
    return;
  }

  handleOpenDocOrImage = ({ document: doc }) => {
    window.open(doc, "_blank");
  };
  prepareTableData = (doc) => {
    let tableData = (doc || [])
      ?.filter((item) => item?.visibility === true)
      .map((item) => {
        return [
          <p>{getMeaningfulDate(item?.date)}</p>,
          <p onClick={() => this.handleOpenDocOrImage(item)} id="doc-name-txt">
            {item?.name}
          </p>,
          <p id="doc-type-container">
            {getFileType(item?.document).toUpperCase()}
          </p>,
          <p onClick={() => this.handleOpenDocOrImage(item)}>View</p>,
        ];
      });
    return tableData;
  };
  parseData = (data) => {
    if (typeof data === "string") {
      return parseJSON(data);
    }
    return data;
  };

  prepareManagerData = (managers) => {
    let tableData = (managers || [])?.map((item) => {
      return [
        <p>
          {capitalizeMe(item?.user?.first_name) +
            " " +
            capitalizeMe(item?.user?.last_name)}
        </p>,
        <p>{item?.user?.email}</p>,
        <p>{item?.user?.phone_number}</p>,
        <p>{this.parseData(item?.user?.info)?.status}</p>,
      ];
    });
    return tableData;
  };

  render() {
    const { asset } = this.state;
    return (
      <div id="asset-detail-wrapper">
        <div style={{marginBottom:20}}>
          <BackButton />
        </div>
        <div className="img-and-title-container">
          <div style={{ position: "relative" }}>
            <img src={asset?.image || logo} alt="logo" id="asset-img" />
          </div>

          <div id="asset-info-txts-container">
            <div>
              <h2>{asset?.name}</h2>
              <p className="asset-id-type">
                {asset?.identification_number_name}
              </p>
              <p className="asset-id-number">{asset?.identification_number}</p>
            </div>
          </div>
        </div>
        {/* ============== About section ================================ */}

        <div id="asset-detail-container">
          <Title>About Asset</Title>
          <p>{asset?.description || "Something about your asset.."}</p>
        </div>
        {/* ============== Manager section ================================ */}
        <div id="asset-detail-container">
          <Title>Asset Manager</Title>

          <div style={{ marginTop: 10, marginBottom: "4rem" }}>
            {asset?.managers ? (
              <Table
                columns={["Name", "Email", "Phone", "Status"]}
                data={this.prepareManagerData(asset?.managers)}
                hoverAnimation={false}
              />
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "70hv",
                }}
              >
                <NoItems text="You have Not Added managers yet. Click 'Add Manager' to add. " />
              </div>
            )}
          </div>
        </div>

        {/* ============== Docs section ================================ */}
        <div id="asset-doc-container">
          <Title style={{ marginBottom: "2rem" }}>DOCUMENTS</Title>
          {asset?.documents?.length > 0 ? (
            <div>
              <Table
                columns={["Created At", "Name", "Type", "Open File"]}
                data={this.prepareTableData(asset?.documents)}
                hoverAnimation={false}
              />
            </div>
          ) : (
            <NoItems text="This asset has no documents yet!" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      deals: state.myDeals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetDetail);
