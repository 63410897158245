import React, { Component } from "react";
import { connect } from "react-redux";
import "./FeedsList.css";
import {  NoItems } from "@kehillahglobal/ui";
import FeedsCard from "../feeds-card/FeedsCard";
import SearchBar from "../../../shared/components/SearchBar/SearchBar";

class FeedsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearching:false,
      results:[]
    };
  }

  updateSearchStatus = (result, queryText, searchFilters) => {
    if (queryText) {
      this.setState({
        isSearching: true,
        results: result,
      });
    } else {
      this.setState({ results: [], isSearching: false });
    }
  }


  render() {
    let { feeds } = this.props;
    let { isSearching, results } = this.state;

    const data = isSearching ? results : feeds;
    return (
      <div
        style={{
          width: "90%",
          margin: "auto",
          position: "relative",
        }}
      >
        <SearchBar
          placeholder="Search feeds"
          data={feeds?.map((item) => ({...item, deal_name: item.deal?.name, enterprise_name: item.deal?.enterprise?.name}))}
          searchFields={["title", "deal_name", "enterprise_name"]}
          updateSearchStatus={this.updateSearchStatus}
        />
        <div className="feeds-list-container">
          {data?.length>0  ?(
            data.map((feed, index) => {
              return <FeedsCard key={index} feed={feed} />;
            })
          ) : (
            <div style={{height:'70vh', display:'flex', alignItems:'center'}}>

              <NoItems text="No feeds added yet." />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    feeds: state.dealFeeds,
  };
};


export default connect(mapStateToProps, null)(FeedsList);
