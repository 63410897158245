import React, { Component } from "react";
import { connect } from "react-redux";
import "./FeedDetail.css";
import { getMeaningfulDate, getFileType, isEmpty } from '../../../shared/js/utils';
import { IMAGE_TYPES, VIDEO_TYPES } from "../../../shared/js/Konstants";
import Processing from "../../../shared/components/processing/Processing";
import BackButton from "../../../shared/components/back-button/BackButton";

class FeedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feed:{}
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    if(id){
      let feed = this.props.feeds.find(feed => feed.id === id) || {};
      this.setState({feed})
    }
  }

  static getDerivedStateFromProps(props, state){
      let id = props.match.params.id;
      if (id) {
        let feed = props.feeds.find((feed) => feed.id === id) || {};
        return {
          feed
        }
      }

      return {}
  }

  showMedia = (file) => {
    if (!file) return;

    let type = getFileType(file);
    
    if (IMAGE_TYPES.includes(type))
      return (
        <div id="feed-card-body-images">
          <img  alt="" src={file} controls />
        </div>
      );
    else  if (VIDEO_TYPES.includes(type))
      return (
        <div id="feed-card-body-images">
          <video src={file} controls />
        </div>
      );

    else
      return (
        <div
          className="pdf-container"
          onClick={() => window.open(file, "_blank")}
        >
          <p>Click to View Attachment</p>
        </div>
      );
  };

  // WHY: We will need comments in the future
  // showComment = () => {
  //   return (
  //     <div className="comment-item-container " style={{position:"relative"}}>
  //       {this.state.showImagePreview ? (
  //         <div
  //           id="image-preview-overlay-root"
  //           onClick={() => this.closePreview()}
  //         >
  //           <img src={this.state.imagePreviewUrl} alt="  " />
  //         </div>
  //       ) : null}
  //       <div style={{ display: "flex" }}>
  //         <div id="commenter-img-container">
  //           <img src={"https://i.pravatar.cc/300"} alt="  " />
  //         </div>
  //         <div id="commenter-info">
  //           <h4>Sam OA</h4>
  //           <small>4d</small>
  //         </div>
  //       </div>
  //       <div id="comment-body">
  //         <p>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // };
  render() {

    let { feed } = this.state;
    return feed?.id ? (
      <div className="feed-detail-root">
        <div style={{marginBottom:20}}>
          <BackButton />
        </div>
        <div id="feed-card-logo-and-name-container">
          <div id="feed-detail-logo-container">
            <img
              src={feed?.deal?.enterprise?.logo || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>

          <div id="feed-card-opp-det">
            <h2>{feed?.deal?.name}</h2>
            <p>{feed?.deal?.enterprise?.name}</p>
            <small>{getMeaningfulDate(feed?.date)}</small>
          </div>
        </div>

        <div id="feed-title-and-body-container">
          <div id="feed-detail-title-container">
            <h1>{feed?.title || ""}</h1>
          </div>

          <div className="feed-detail-body-container">
            <div
              className="optimum-padding-right-left feed-detail-body"
              dangerouslySetInnerHTML={{
                __html: isEmpty(feed?.body) ? "" : feed?.body,
              }}
            ></div>
            <div style={{ marginTop: "5rem" }}>
              {this.showMedia(feed?.file)}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Processing text="loading..."></Processing>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    feeds: state.dealFeeds|| {},
  };
};


export default connect(mapStateToProps, null)(FeedDetail);
