export const IMAGE_TYPES = ["jpeg", "jpg", "png"];
export const VIDEO_TYPES = ["mp4", "avi", "mov"];
export const DISTRIBUTION_TYPES = {
  BALANCE: "BALANCE",
  PERCENTAGE: "PERCENTAGE",
};

export const ACCOUNT_TYPES = {
  BANK: "BANK",
  MOBILE: "MOBILE",
};

export const DISTRIBUTION_STATUSES = {
  COMPLETED: "COMPLETED",
  CREATED: "CREATED",
  PAYMENT_MADE: "PAYMENT_MADE",
  PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
};

export const PARTNERSHIP_TYPES = {
  ENTERPRISE: "ENTERPRISE",
  INDIVIDUAL: "INDIVIDUAL",
  TEAM: "TEAM",
};

export const DATA_TYPES = {
    STRING:"string",
    NUMBER:"number",
    BOOLEAN:"boolean",
    OBJECT:'object',
}

export const ASSET_MANAGER_WORK_STATUS = {
    PROBATION:"PROBATION",
    WORKING:"WORKING",
    TERMINATED:"TERMINATED",
}

export const FILE_EXTENSIONS = {
    PDF:'pdf',
    DOC:'doc',
    DOCX:'docx',
    JPEG:'jpeg',
    PNG:'png',
    JPG:'jpg',
    MP4:'mp4',
    MOV:'mov',
    AVI:'avi',
    MP3:'mp3',
    

}