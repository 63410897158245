import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { TabView } from "@kehillahglobal/ui";
import UserSettings from '../UserSetting';




class UserSettingsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { changer: "Details" };
  }

  TABS = [
    // {
    //   id: "Themes",
    //   name: "Themes",
    //   component: (
    //     <ThemePreference
    //       switchTabs={(changer) => this.state.changer(changer)}
    //     />
    //   ),
    // },
    // {
    //   id: "Notifications",
    //   name: "Notifications",
    //   component: (
    //     <NotificationPreference
    //       switchTabs={(changer) => this.state.changer(changer)}
    //     />
    //   ),
    // },
    {
      id: "Details",
      name: "Profile Information",
      component: (
        <UserSettings switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
    {
      id: "Verification",
      name: "Verification infomation",
      component: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <h1 style={{ color: "var(--app-accent-pink)", fontSize:'5rem' }}>COMING SOON</h1>
        </div>
      ),
    },
    // WHY: Will be needed in the future
    // {
    //   id: "other-settings",
    //   name: "Other Settings",
    //   component: (
    //     <OtherSettings
    //       switchTabs={(changer) => this.state.changer(changer)}
    //     />
    //   ),
    // },
  ];
  render() {
    return (
      <div className='user-settings-wrapper-root-container'>
        <TabView
          data={this.TABS}
          defaultTab={this.state.changer}
          onMount={(changer) => this.setState({ changer })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      //   toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsWrapper);