import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faImage,
  faFileAlt,
  faFileVideo,
} from "@fortawesome/free-solid-svg-icons";
import { Table, NoItems } from "@kehillahglobal/ui";
import { getFileType, getMeaningfulDate } from "../../../../shared/js/utils";
import {
  FILE_EXTENSIONS,
  IMAGE_TYPES,
  VIDEO_TYPES,
} from "../../../../shared/js/Konstants";
import { withRouter } from "react-router-dom";

class DealDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deal: {},
    };
  }
  static getDerivedStateFromProps(props, state) {
    let { dealID } = props?.match?.params;
    if (dealID) {
      let deal = props?.deals?.find((item) => item?.deal.id === dealID);
      return {
        deal,
      };
    }
    return;
  }

  handleOpenDocOrImage = (doc) => {
    window.open(doc, "_blank");
  };

  showType = (file) => {
    if (!file) return;
    const fileType = getFileType(file);

    if (IMAGE_TYPES.includes(fileType))
      return <FontAwesomeIcon icon={faImage} fontSize="3rem" color="maroon" />;
    else if (fileType === FILE_EXTENSIONS.PDF)
      return (
        <FontAwesomeIcon icon={faFilePdf} fontSize="3rem" color="maroon" />
      );
    else if (VIDEO_TYPES.includes(fileType))
      return (
        <FontAwesomeIcon icon={faFileVideo} fontSize="3rem" color="maroon" />
      );

    return <FontAwesomeIcon icon={faFileAlt} fontSize="3rem" color="maroon" />;
  };

  prepareTableData = (docs = []) => {
    let tableData = docs
      ?.filter((item) => item?.is_published === true)
      ?.map((item, index) => {
        return [
          <p>{index + 1}</p>,
          <p>{getMeaningfulDate(item?.date)}</p>,
          <p onClick={() => this.handleOpenDocOrImage(item)} id="doc-name-txt">
            {item?.title}
          </p>,
          <p id="doc-type-container">
            {getFileType(item?.document).toUpperCase()}
          </p>,
          <p onClick={() => this.handleOpenDocOrImage(item?.document)}>View</p>,
        ];
      });
    return tableData;
  };
  render() {
    let { deal } = this.state;
    let documents = deal?.deal?.documents?.filter(
      (item) => item?.is_published === true
    );
    return (
      <div id="deal-docs-root">
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          {documents?.length > 0 ? (
            <Table
              columns={["#", "Created At", "Name", "Type", "Open File"]}
              data={this.prepareTableData(documents)}
              hoverAnimation={false}
              rootClass="opp-docs-table"
            />
          ) : (
            <div className="no_item_found_display">
              <NoItems text="No document added by enterprise" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state.myDeals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealDocuments)
);
