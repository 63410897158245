
export const DEAL_DETAILS_TAB_KEY =  "deal-details-tab";
export const DEAL_ASSETS_TAB_ID = "deal-assets-tab";
export const DEAL_DOCUMENTS_TAB_ID = "deal-documents-tab";
export const DEAL_FEEDS_TAB_ID = "deal-feeds-tab";
export const DEAL_REVENUE_TAB_ID = "deal-revenue-tab";
export const DEAL_EXPENSES_TAB_ID = "deal-expenses-tab";
export const DEAL_ACQUISITION_STATUS_TAB_ID = "acquisition-status";





export const DEAL_TAB_IDS = [
  DEAL_DETAILS_TAB_KEY,
  DEAL_ASSETS_TAB_ID,
  DEAL_DOCUMENTS_TAB_ID,
  DEAL_FEEDS_TAB_ID,
  DEAL_REVENUE_TAB_ID,
  DEAL_EXPENSES_TAB_ID,
DEAL_ACQUISITION_STATUS_TAB_ID
];