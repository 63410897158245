import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormGenerator } from "@kehillahglobal/ui";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { updateUserInfoAction } from "../../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import { CLIENT_PREFIX } from "../../../../shared/js/utils";

class CreateOrUpdateSocialInfo extends Component {
  constructor(props) {
    super(props);
    this.socialInfoRef = React.createRef();
  }

  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.saveDataToDB());
    }
  }

  saveDataToDB = async () => {
    let { user } = this.props;
    const data = this.socialInfoRef.current?.onClickFromOutside();
    let res;
    if (data) {
      data.user_id = user?.id;
      if (user?.contacts !== null) {
        data.id = user?.contacts?.id;
        res = await ApiCallHandler.send(URLS.UPDATE_CONTACTS, "POST", data);
      } else {
        res = await ApiCallHandler.send(URLS.CREATE_CONTACTS, "POST", data);
      }


      console.log("===== RES =======", res)
      if (res && res?.success) {
        let newUser = user;
        newUser.contacts = user?.contacts !== null ? res?.data[0] : res?.data;
        this.props.updateUserInfo(newUser);
        this.props.history.push(CLIENT_PREFIX + "/settings");
      }
    }
  };
  render() {
    let { user } = this.props;
    const formData = [
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Website URL",
        name: "website",
        placeholder: "Website URL",
        value: user?.id ? user?.contacts?.website : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Twitter URL",
        name: "twitter",
        placeholder: "Twitter handle URL",
        value: user?.id ? user?.contacts?.twitter : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Facebook",
        name: "facebook",
        placeholder: "Facebook handle URL",
        value: user?.id ? user?.contacts?.facebook : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "Instagram",
        name: "instagram",
        placeholder: "Instagram handle URL",
        value: user?.id ? user?.contacts?.instagram : "",
      },
      {
        fieldType: FormGenerator.Fields.INPUT,
        label: "LinkedIn",
        name: "linkedin",
        placeholder: "LinkedIn handle URL",
        value: user?.id ? user?.contacts?.linkedin : "",
      },
    ];

    return (
      <div>
        {" "}
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          formSubmitBtnText={"Save Changes"}
          fields={formData}
          hideSubmitBtn={true}
          ref={this.socialInfoRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUserInfo: updateUserInfoAction,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrUpdateSocialInfo)
);
