import {
  DO_NOTHING,
  SHOW_SIDE_PANE,
  TOGGLE_TOAST,
  TOGGLE_MODAL,
  LOADING_BLANKET,
  EDIT_SETTER,
  LOAD_MOBILE_WALLETS,
  LOAD_INVESTMENTS,
  LOAD_BANK_WALLETS,
  LOAD_USER_INVITATIONS,
  LOAD_USERS_PAYMENT_ELECTIONS,
  LOAD_USER_DASHBOARD_STATS,
  LOAD_DEAL_REPORTS,
  LOAD_DEAL_DISTRIBUTIONS,
  TOGGLE_IMAGE_PREVIEW,
  LOAD_USER_INFO,
  TOGGLE_OVERLAY,
  FETCH_EXCHANGE_RATES,
  LOAD_PARTNERSHIP_STATS,
} from "../actions/Constants/Constants";

const INIT = ["Hello", "There"];
export const doNothingReducer = (state = INIT, action) => {
  if (action.type === DO_NOTHING) {
    return action.payload;
  }
  return state;
};


export const setEditSetterReducer = (state = {}, action) => {
  if (action.type === EDIT_SETTER) {
    return action.payload;
  }
  return state;
};



export const toggleSidePaneReducer = (
  state = { on: false, component: null },
  action
) => {
  if (action.type === SHOW_SIDE_PANE) return action.payload || state;
  return state;
};



export const toggleToastReducer = (
  state = { show: false, message: "Default Message" },
  action
) => {
  if (action.type === TOGGLE_TOAST) return action.payload;
  return state;
};
export const toggleImagePreviewReducer = (
  state = { show: false, image: null },
  action
) => {
  if (action.type === TOGGLE_IMAGE_PREVIEW) return action.payload;
  return state;
};
export const toggleOverlayReducer = (state = { show: false, image: null }, action) => {
  if (action.type === TOGGLE_OVERLAY) return action.payload;
  return state;
};


export const toggleModalReducer = (
  state = { show: false, props: {} },
  action
) => {
  if (action.type === TOGGLE_MODAL) return action.payload;
  return state;
};
export const toggleLoadingBlanketReducer = (
  state = { show: false, props: {} },
  action
) => {
  if (action.type === LOADING_BLANKET) return action.payload;
  return state;
};
export const getUserMobileMoneyWalletsReducer = (state = [], action) => {
  if (action.type === LOAD_MOBILE_WALLETS) return action.payload;
  return state;
};
export const getUserBankWalletsReducer = (state = [], action) => {
  if (action.type === LOAD_BANK_WALLETS) return action.payload;
  return state;
};

export const getUserDealsReducer = (state = [], action) => {
  if (action.type === LOAD_INVESTMENTS) return action.payload;
  return state;
};

export const getUserDealReportsReducer = (state = [], action) => {
  if (action.type === LOAD_DEAL_REPORTS) return action.payload;
  return state;
};

export const getUserDealDistributionsReducer = (state = [], action) => {
  if (action.type === LOAD_DEAL_DISTRIBUTIONS) return action.payload;
  return state;
};


export const listUsersPaymentElectionsReducer = (state = [], action) => {
  if (action.type === LOAD_USERS_PAYMENT_ELECTIONS) return action.payload;
  return state;
};
export const getUserInvitationsReducer = (state = [], action) => {
  if (action.type === LOAD_USER_INVITATIONS) return action.payload;
  return state;
};
export const userDashboardStatsReducer = (state = {}, action) => {
  if (action.type === LOAD_USER_DASHBOARD_STATS) return action.payload;
  return state;
};
export const getPartnershipStatsReducer = (state = {}, action) => {
  if (action.type === LOAD_PARTNERSHIP_STATS) return action.payload;
  return state;
};


export const getUserInfoReducer = (state = {}, action) => {
  if (action.type === LOAD_USER_INFO) return action.payload;
  return state;
};
export const fetchExchangeRatesReducer = (state = {}, action) => {
  if (action.type === FETCH_EXCHANGE_RATES) return action.payload;
  return state;
};