import { combineReducers } from "redux";
import {
  doNothingReducer,
  toggleSidePaneReducer,
  toggleToastReducer,
  toggleModalReducer,
  toggleLoadingBlanketReducer,
  setEditSetterReducer,
  getUserMobileMoneyWalletsReducer,
  getUserBankWalletsReducer,
  getUserDealsReducer,
  listUsersPaymentElectionsReducer,
  getUserInvitationsReducer,
  userDashboardStatsReducer,
  getUserDealReportsReducer,
  getUserDealDistributionsReducer,
  toggleImagePreviewReducer,
  getUserInfoReducer,
  toggleOverlayReducer,
  fetchExchangeRatesReducer,
  getPartnershipStatsReducer,
} from "./all";

const motherReducer = combineReducers({
  nothing: doNothingReducer,
  sidePane: toggleSidePaneReducer,
  toastProps: toggleToastReducer,
  imagePreviewProps: toggleImagePreviewReducer,
  overlayProps: toggleOverlayReducer,
  modalProps: toggleModalReducer,
  blanketProps: toggleLoadingBlanketReducer,
  editStatusPayload: setEditSetterReducer,
  mobileMoneyWallets: getUserMobileMoneyWalletsReducer,
  bankWallets: getUserBankWalletsReducer,
  myDeals: getUserDealsReducer,
  paymentElections: listUsersPaymentElectionsReducer,
  invitations: getUserInvitationsReducer,
  dashboardStats: userDashboardStatsReducer,
  dealFeeds: getUserDealReportsReducer,
  myDistributions: getUserDealDistributionsReducer,
  userData: getUserInfoReducer,
  exchangeRates: fetchExchangeRatesReducer,
  partnershipStats:getPartnershipStatsReducer
});

export default motherReducer;
