import React from 'react'
import "./user-deals-details.css";
import { useSelector } from 'react-redux';
import { getMeaningfulDate } from '../../../../shared/js/utils';
import Title from '../../../../components/title/Title';
import {FormGenerator} from '@kehillahglobal/ui';
import ApiCallHandler from '../../../../shared/js/ApiCallHandler';
import URLS from '../../../../shared/js/urls';
import { useDispatch } from 'react-redux';
import { getAllDealsAction } from '../../../../store/actions/actions';
import {Accordion} from '@kehillahglobal/ui';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';


export default function AcquisitionStatus() {
  const params = useParams();
  const deal = useSelector((state) =>
    state.myDeals.find((deal) => deal?.deal?.id === params?.dealID)
  );

  const invitation = useSelector((state) =>
    state.invitations.find((item) => item?.deal?.id === params?.dealID)
  );

  const dispatch = useDispatch("");

  const paymentOptions = [
    {
      title: "Bank Payment",
      content: <BankOption />,
    },
    {
      title: "Mobile Payment",
      content: <MobileOption />,
    },
  ];

  const STAGES = [
    {
      name: "Invitation Received",
      date: invitation?.created_at,
    },
    {
      name: "Invitation Accepted",
      date: getMeaningfulDate(deal?.created_at),
    },
    {
      name: "Payment Received",
      date: deal?.has_received_payment && getMeaningfulDate(deal?.payment_date),
    },

    {
      name: "Approved",
      date: deal?.is_approved && getMeaningfulDate(deal?.updated_at),
    },
  ];

  const checkStatus = () => {
    if (deal?.is_approved) {
      return STAGES.length;
    } else if (deal?.has_received_payment) {
      return STAGES.length - 1;
    } else {
      return 2;
    }
  };

  // eslint-disable-next-line
  const showStages = () => {
    return STAGES.map((status, index) => {
      return (
        <div
          className={`elevate-float status-root ${
            index < checkStatus() && "completed"
          }`}
        >
          <div
            className={`status-item ${
              index < checkStatus() && "completed-status-item"
            }`}
          >
            <p>{status.name}</p>
            {status?.date && <span>{getMeaningfulDate(status?.date)}</span>}
          </div>
        </div>
      );
    });
  };

  const handleSubmit = (data, reset) => {
    let toSend = {
      id: deal?.id,
      payment_receipt: data?.payment_receipt?.data?.file?.data,
    };

    ApiCallHandler.send(
      URLS.CREATE_PARTNERSHIP_PAYMENT_RECEIPT,
      "POST",
      toSend
    ).then((res) => {
      if (res?.success) {
        dispatch(getAllDealsAction());
        reset();
      }
    });
  };
  // eslint-disable-next-line
  const showDescriptionAndForm = () => {
    return (
      <div className="acquisition-info-container">
        <div className="acquisition-desc-container">
          <Title>Payment Procedure</Title>

          <Accordion data={paymentOptions} icons={[faAngleDown, faAngleUp]} />
        </div>

        <div className="receipt-upload-form-container">
          <FormGenerator
            elevation={0}
            subtitle=" "
            title=" "
            formSubmitBtnText={"Upload Receipt"}
            fields={[
              {
                fieldType: FormGenerator.Fields.FILE,
                label: "Proof of Payment",
                name: "payment_receipt",
                placeholder: "Upload Proof of Payment",
                value: deal?.payment_receipt || "",
              },
            ]}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="acquisition-status-root">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "80vw",
          height: "55vh",
        }}
      >
        <Title
          style={{
            fontSize: "5rem",
          }}
        >
          COMING SOON
        </Title>
      </div>
      {/* {showDescriptionAndForm()}
        <div>{showStages()}</div> */}
    </div>
  );
}



const BankOption =()=>{
  return (
    <div className="bank-option-container">
      <p>Please Visit any GCB branch and make payment to the account below.</p>
      <ul>
        <li>Account Name: Kehillah Investment</li>
        <li>Account Number: 000123456789</li>
      </ul>
      <p>Please upload the receipt after payment. Thank you. </p>
    </div>
  );
}
const MobileOption =()=>{
  return (
    <div className="mobile-money-option-container">
      <p>
        You should receive a prompt on your mobile number to enter your PIN to
        authorize the payment.
      </p>
      <p>
        If you do not receive the prompt within 10 seconds follow the
        instructions below:
      </p>

      <ol>
        <li>Dial *170# to see the main MTN USSD menu</li>
        <li>If the prompt appears instead, cancel it and dial *170# again</li>
        <li>Choose 6) My Wallet</li>
        <li>Choose 3) My Approvals</li>
        <li>Enter your PIN to proceed</li>
        <li>
          Look for the transaction and follow the prompts to authorize it. Make
          sure the amount is correct
        </li>
        <li>
          You have 5 mins to authorize the transaction so if anything goes
          wrong, simply dial and try again
        </li>
        <li>
          After payment take a screenshot of the transaction and send it to us using the uploader below.
        </li>
      </ol>
    </div>
  );
}