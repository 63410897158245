import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModalAction } from "../../store/actions/actions";
import MobileWallet from './mobile money wallets/MobileWallet';
import { TabView } from "@kehillahglobal/ui";
import PaymentPreferences from "./payment elections/PaymentPreferences";
import BankWallet from './bank accounts/BankWallet'
class WalletWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "payment-preferences" };
  }

  TABS = [
    {
      name: "Payment Preferences",
      id: "payment-preferences",
      component: (
        <PaymentPreferences
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      id: "bank-wallet",
      name: "Bank Accounts",
      component: (
        <BankWallet
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      id: "mobile-wallet",
      name: "Mobile Money Wallet",
      component: (
        <MobileWallet switchTabs={(changer) => this.state.changer(changer)} />
      ),
    }
  ];
  render() {
    return (
        <div style={{ width: "94%", margin: "auto", marginTop:"1rem" }}>
          <TabView
            data={this.TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            defaultTab={this.state.changer}
            onMount={(changer) => this.setState({ changer })}
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletWrapper);
