import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import "./DistributionsList.css";
import { Table, NoItems } from "@kehillahglobal/ui";
import { CLIENT_PREFIX, convertNumberToShortForm, getMeaningfulDate, getMyCurrencyInfo, getRate } from "../../../shared/js/utils";
class DistributionsList extends Component {


  prepareTableData = (distributions) => {
    return distributions.map((item, index) => {
      let figures = this.convertDistributionFigures(item)
      return [
        <p>{index+1}</p>,
        <p onClick={() => this.handleViewDetails(item?.id)}>
          {getMeaningfulDate(item?.date)}
        </p>,
        <p onClick={() => this.handleViewDetails(item?.id)}>
          {item?.deal?.enterprise?.name}
        </p>,
        <p>{item?.deal?.name}</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(figures?.amount.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "tomato" }}>
          {convertNumberToShortForm(figures?.charges.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "grey" }}>
          {convertNumberToShortForm(figures?.fullAmount.toFixed(2))}
        </p>,
        <p>{item?.payment_status}</p>,
      ];
    });
  };

  handleViewDetails = (id) => {
    this.props.history.push(CLIENT_PREFIX+`/deals/distributions/${id}`);
  }


  convertDistributionFigures = (distribution) => {
    let {rates} = this.props;
    let currency = getMyCurrencyInfo(distribution?.deal?.enterprise?.currency)
    return {
      amount: distribution?.amount_to_receive * getRate(currency?.code, rates),
      charges: distribution?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: distribution?.full_amount * getRate(currency?.code, rates),
    }

  }

  render() {
    let { distributions, user} = this.props;
    let currency =  getMyCurrencyInfo(user?.currency);
    return (
      <div style={{ width: "92%", margin: "auto", marginTop: "1rem" }}>
        <Header
          title="Distributions"
          subtitle="List of all your distributions"
        />

        <div style={{ marginTop: "2rem" }}>
          {distributions.length > 0 ? (
            <Table
              columns={[
                "#",
                "Date",
                "Sender",
                "Deal",
                `Amount (${currency.symbol})`,
                `Charges (${currency.symbol})`,
                `Full Amount  (${currency.symbol})`,
                "Status",
              ]}
              data={this.prepareTableData(distributions)}
              hoverAnimation={false}
              rootClass="distributions-table"
            />
          ) : (
            <NoItems text="No Distributions" />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    distributions:state.myDistributions,
    user:state.userData,
    rates:state.exchangeRates
  };
};

export default connect(mapStateToProps, null)(DistributionsList);
