import React, {useState} from 'react'
import EditProfileInfo from './Tabs/EditProfile';
import CreateOrEditUserContacts from './Tabs/CreateOrEditUserContacts';
import { faAddressBook, faGlobe, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Stepper } from "@kehillahglobal/ui";
import { useHistory } from 'react-router-dom';
import CreateAndUpdateSocialInfo from './Tabs/CreateAndUpdateSocialInfo';


export default function EditSettingsWrapper() {
  let history = useHistory();

const [submitFunctionForEditProfileInfo, setSubmitFunctionForEditProfileInfo] =useState("");
const [submitFunctionForContactInfo, setSubmitFunctionForContactInfo] =useState("");
const [submitFunctionForSocialInfo, setSubmitFunctionForSocialInfo] =useState("");
  const onNext = (step) => {
    if (step === 0) {
      submitFunctionForEditProfileInfo();
    } else if (step === 1) {
      submitFunctionForContactInfo();
    }
    else if (step === 2) {
      submitFunctionForSocialInfo();
    }
  };
  const steps = [
    {
      name: "Profile Info",
      component: (
        <EditProfileInfo
          onMount={(onSubmit) =>
            setSubmitFunctionForEditProfileInfo(() => onSubmit)
          }
        />
      ),
      icon: faInfo,
    },
    {
      name: "Contacts",
      component: (
        <CreateOrEditUserContacts
          onMount={(onSubmit) =>
            setSubmitFunctionForContactInfo(() => onSubmit)
          }
        />
      ),
      icon: faAddressBook,
    },
    {
      name: "Socials",
      component: (
        <CreateAndUpdateSocialInfo
          onMount={(onSubmit) => setSubmitFunctionForSocialInfo(() => onSubmit)}
        />
      ),
      icon: faGlobe,
    },
  ];

  const onCancel = ()=>{
    history.goBack()
  }
  return (
    <div>
      <Stepper variant={"horizontal"} steps={steps} onNext={onNext} onCancel={onCancel} />
    </div>
  );
}
