import React, { Component } from "react";
import "./DealCard.css";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { capitalizeMe, CLIENT_PREFIX, convertNumberToShortForm, getMyCurrencyInfo, getRate } from "../../../shared/js/utils";
import { connect } from 'react-redux';

class DealCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculatesAvailableShares = (partnerships) => {
    let { deal } = this.props;

    if (partnerships && partnerships.length) {
      let totalShares = 0;
      partnerships?.forEach((partnership) => {
        totalShares += partnership?.shares;
      });
      return deal?.shares - totalShares;
    }

    return deal?.shares;
  };

  handleViewDetails = (deal) => {
    if (deal?.email) {
      this.props.history.push({
        pathname: CLIENT_PREFIX+`/deals/invitation/${deal?.id}`,
      });
    } else {
       sessionStorage.is_approved = deal?.is_approved;
      this.props.history.push({
        pathname: CLIENT_PREFIX+`/deals/${deal?.deal?.id}/partnerships/${deal?.id}`,
      });
    }
  };
  truncateTextBody = (text) => {
    let toShow = text?.split("</p>")[0];
    return toShow?.substring(0, 300) + "...";
  };

  handleViewEnterpriseDetail = (deal) => {
    this.props.history.push(
      CLIENT_PREFIX+"/partnership/enterprise/" + deal?.deal?.enterprise?.id
    );
  };

  showBadge = () => {
    let { deal } = this.props;
    if (deal?.is_approved === true) return;
    return (
      <div
        className={`inv-card-badge-container ${deal?.email && "pending"}`}
      >
        <p>
          {deal?.email
            ? "Pending Acceptance from you."
            : "Awaiting Admin Approval"}
        </p>
      </div>
    );
  };
  convertMoney = (money, code) => {
    let { rates } = this.props;
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };

convertMonthsToYears = (months) => {
  if (!months) return 0 + " mos";
  let years = months / 12;
  if (years < 1) return months + " mos";
  if (years === 1) return 1 + " yr";
  if (years > 1 && months % 12 === 0) return years.toFixed(0) + "yrs";
  // eslint-disable-next-line
  return years.toFixed(0) + " yrs" + " " + (months % 12) + " mos";
}


  render() {
    let { deal, user } = this.props;
    let enterpriseCurrency = getMyCurrencyInfo(
      deal?.deal?.enterprise?.currency
    );
    let userCurrency = getMyCurrencyInfo(user?.currency);
    return (
      <>
        <div
          id="invest-card-wrapper"
          style={{ cursor: "pointer", height: "max-content" }}
          className="invest-card-wrapper"
        >
          <div id="invest-card-wrapper">
            <div id="image-and-verify-container">
              <div id="com-image-container">
                <img
                  src={
                    deal?.deal?.enterprise?.logo || "https://i.pravatar.cc/300"
                  }
                  alt="  "
                />
              </div>

              <div
                id="company-name-container"
                onClick={() => this.handleViewEnterpriseDetail(deal)}
              >
                <span>{capitalizeMe(deal?.deal?.enterprise?.name)}</span>
              </div>
            </div>
            <div
              id="name-and-desc-container"
              onClick={() => this.handleViewDetails(deal)}
            >
              <h3>{deal?.deal?.name}</h3>

              <div
                style={{ cursor: "pointer" }}
                className="deal-card-body"
                dangerouslySetInnerHTML={{
                  __html: this.truncateTextBody(deal?.deal?.about),
                }}
              ></div>
            </div>

            <div
              id="badge-root-wrapper"
              onClick={() => this.handleViewDetails(deal)}
            >
              {this.showBadge()}
            </div>
          </div>

          <div
            id="deal-money-container"
            onClick={() => this.handleViewDetails(deal)}
          >
            <div id="subscriptions-container">
              <div>
                <span>Shares</span>
                <p>{deal?.shares}</p>
              </div>
              <div>
                <span>Per Share</span>
                <p>
                  {" "}
                  {enterpriseCurrency?.symbol}{" "}
                  {convertNumberToShortForm(parseFloat(deal?.deal?.price_per_share))}
                  ({userCurrency?.symbol}{" "}
                  {   convertNumberToShortForm(this.convertMoney(
                      parseFloat(deal?.deal?.price_per_share),
                    enterpriseCurrency?.code
                  )?.toFixed(2))}
                  )
                </p>
              </div>

              <div id="duration-container">
                <span>Runs for</span>
                <p>
                  {this.convertMonthsToYears(
                    deal?.deal?.deal_running_period_in_months
                  )}{" "}
                </p>
              </div>
            </div>

            <div id="return-percentages-container">
              <div>
                <span>Carry</span>
                <p>{deal?.deal?.carry} %</p>
              </div>
              <div>
                <span>Mgmt. Fee</span>
                <p>
                  {enterpriseCurrency?.symbol} {deal?.deal?.mgmt_fee} (
                  {userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    deal?.deal?.mgmt_fee,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div>
                <span>Admin Charges</span>
                <p>{deal?.deal?.admin_fee} %</p>
              </div>
            </div>
          </div>
          <div id="footer-btns-container">
            <div
              id="view-more-btn-container"
              onClick={() => this.handleViewDetails(deal)}
            >
              <p>View More</p>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.exchangeRates,
    user:state.userData
  };
}

export default withRouter(connect(mapStateToProps, null)(DealCard));
