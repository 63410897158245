export const emailValidator = (email) => {
  if (!email) return false;
  // eslint-disable-next-line
  let re =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let passed = re.test(String(email).toLowerCase());
  return {
    passed,
    message: !passed && "Please enter a valid email",
  };
};

export const numberValidator = (number) => {
  if (!number) return false;
  let re = /^\d+$/;
  let passed = re.test(number);
  return {
    passed,
    message: !passed && "Please enter a valid number",
  };
};

export const phoneNumberValidator = (number) => {
  if (!number) return false;
  // eslint-disable-next-line
  let re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  let passed = re.test(number);

  return {
    passed,
    message: !passed && "Please enter a valid phone number",
  };
};
