import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormGenerator } from "@kehillahglobal/ui";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { isEmpty, parseJSON } from '../../../../shared/js/utils';
import {
  toggleToastAction,
  updateUserInfoAction,
} from "../../../../store/actions/actions";

class EditProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.editProfile = React.createRef();
  }

  componentDidMount() {
    let { onMount } = this.props;
    if (onMount) {
      onMount(() => this.handleSubmit());
    }
  }

  addOrUpdateUserInterests = async (data) => {
    let { userData: user } = this.props;
    let toSend = {
      user: user?.id,
      interests: JSON.stringify(data),
    };

    if (isEmpty(user?.interests)) {
      await ApiCallHandler.send(
        URLS.CREATE_USER_INTERESTS,
        "POST",
        toSend
      );
    } else {
      toSend.id = user?.interests?.id;
       await ApiCallHandler.send(URLS.UPDATE_USER_INTERESTS, "POST",
        toSend
      );
    }
  };

  handleSubmit = async () => {
    let { userData } = this.props;
    const data = this.editProfile.current?.onClickFromOutside();
    if (data) {
      const toSend = {
        ...data,
        id: userData?.id,
      };

      this.addOrUpdateUserInterests(toSend?.interests);
      let res;

      delete toSend?.interests;

      res = await ApiCallHandler.send(URLS.UPDATE_USERS, "POST", toSend);
      if (res && res.success) {
        this.props.updateUserInfo(res?.data[0]);
      } else {
        this.props.toggleToast({
          showToast: true,
          message: "An error occurred. Try again",
          type: "failure",
        });
      }
    }
  };

  extractUserInterests = (stringifiedData) => {
    if (isEmpty(stringifiedData?.interests)) return [];
    let ints = parseJSON(stringifiedData.interests);
    return ints;
  };
  render() {
    let { userData } = this.props;
    const Fields = FormGenerator.Fields;
    return (
      <div>
        <FormGenerator
          elevation={0}
          fields={[
            {
              fieldType: Fields.INPUT,
              label: "First Name",
              name: "first_name",
              placeholder: "First name",
              required: false,
              value: userData ? userData.first_name : "",
            },
            {
              fieldType: Fields.INPUT,
              label: "Last Name",
              name: "last_name",
              placeholder: "Last name",
              required: false,
              value: userData ? userData.last_name : "",
            },
            {
              fieldType: Fields.INPUT,
              label: "middle Name",
              name: "middle_name",
              placeholder: "Middle name",
              required: false,
              value: userData ? userData.middle_name : "",
            },
            {
              fieldType: Fields.INPUT,
              label: "Username",
              name: "username",
              placeholder: "Username",
              required: false,
              value: userData ? userData.username : "",
            },
            {
              fieldType: Fields.TAG,
              label: "Interests",
              name: "interests",
              placeholder: "Please press enter or return after each item",
              required: false,
              value:
                userData?.interests !== null
                  ? this.extractUserInterests(userData?.interests)
                  : [],
            },
            {
              fieldType: Fields.RICHTEXT,
              name: "bio",
              label: "Bio",
              placeholder: "Brief description of yourself...",
              required: false,
              value: userData ? userData.bio : "",
            },
          ]}
          subtitle=""
          title=""
          hideSubmitBtn={true}
          ref={this.editProfile}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state?.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleToast: toggleToastAction,
      updateUserInfo: updateUserInfoAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileInfo);
