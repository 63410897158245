import React, { Component } from 'react'
import { FormGenerator } from "@kehillahglobal/ui";
import { onCountryOrCurrencySearch, removeCountriesWithEmptyCurrency, isEmpty, getRandomStringKey } from "../../../../shared/js/utils"



export default class BankInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      key: getRandomStringKey(),
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props) {
      return {
        data: props.data,
      };
    }
    return null;
  }

  extractCountry = (country) => {
    if (!country) return {};
    let _country = country?.split("-");
    return removeCountriesWithEmptyCurrency().find(
      (item) => item?.name === _country[0]
    );
  };
  render() {
    const getFieldValue = (field) => {
        let {data} = this.state;
      let value = !isEmpty(data) ? data[field] : "";
      return value;
    };

    const Fields = FormGenerator.Fields

    const formData = [
      {
        data: removeCountriesWithEmptyCurrency(),
        fieldType: Fields.DROPDOWN,
        type: "full",
        name: "country",
        label: "Country",
        placeholder: "Select Country",
        required: true,
        labelExtractor: (item) => item.name?.toUpperCase(),
        valueExtractor: (item) => item.name,
        onSearch: (value) => onCountryOrCurrencySearch(value),
        value:
          typeof this.state?.data?.country === "string"
            ? this.extractCountry(this.state?.data?.country)
            : getFieldValue("country"),
      },
      {
        data: removeCountriesWithEmptyCurrency(),
        fieldType: Fields.DROPDOWN,
        type: "full",
        name: "currency",
        label: "Currency",
        placeholder: "Select Currency",
        required: true,
        labelExtractor: (item) =>
          `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
            item?.currency?.symbol
          })`,
        valueExtractor: (item) => item?.currency?.name,
        onSearch: (value) => onCountryOrCurrencySearch(value),
        value:
          typeof this.state?.data?.currency === "string"
            ? this.extractCountry(this.state?.data?.currency)
            : getFieldValue("currency"),
      },
      {
        fieldType: Fields.INPUT,
        label: "Bank Name",
        name: "bank_name",
        placeholder: "Bank name",
        required: true,
        value: getFieldValue("bank_name"),
      },

      {
        fieldType: Fields.INPUT,
        label: "Account Number",
        name: "account_number",
        placeholder: "Account Number",
        required: true,
        value: getFieldValue("account_number"),
      },
      {
        fieldType: Fields.INPUT,
        name: "confirm_account_number",
        label: "Confirm Account number",
        placeholder: "Confirm Account number",
        required: true,
      },
      {
        fieldType: Fields.INPUT,
        label: "Bank Branch",
        name: "bank_branch",
        placeholder: "Branch Name",
        required: true,
        value: getFieldValue("bank_branch"),
      },
      {
        fieldType: Fields.INPUT,
        label: "Swift code",
        name: "swift_code",
        placeholder: "Swift Code",
        required: false,
        value: getFieldValue("swift_code"),
      },
      {
        fieldType: Fields.INPUT,
        name: "sort_code",
        label: "Sort Code",
        placeholder: "Sort Code",
        required: false,
        value: getFieldValue("sort_code"),
      },
      {
        fieldType: Fields.INPUT,
        name: "bank_address",
        label: "Bank Address",
        placeholder: "Bank Address",
        required: false,
        value: getFieldValue("bank_address"),
      },
    ];
    return (
      <div key={this.state.key}>
        <FormGenerator
          elevation={0}
          subtitle=""
          title=""
          fields={formData}
          hideSubmitBtn={true}
          onStateChange={
            this.props.onStateChange ? this.props.onStateChange : null
          }
        />
      </div>
    );
  }
}

