import React from "react";
import { Stepper } from "@kehillahglobal/ui";
import UserInfo from "./steps/UserInfo";
import BankInfo from "./steps/BankInfo";
import VerifyBankInfo from "./steps/VerifyBankInfo";
import { useState } from "react";
import {
  faCheckCircle,
  faPiggyBank,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { isEmpty } from "../../../shared/js/utils";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { useDispatch } from "react-redux";
import {
  editMutationAction,
  toggleToastAction,
} from "../../../store/actions/actions";
import { useSelector } from "react-redux";
import Processing from "../../../shared/components/processing/Processing";

export default function CreateBankAccount(props) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userData);
  let wallets = useSelector((state) => state.bankWallets);

  useEffect(() => {
    if (!isEmpty(props?.editData)) {
      setState({
        data: {
          ...state.data,
          ...props.editData,
        },
      });
    }
    // eslint-disable-next-line
  }, [props?.editData]);

  const collectFormData = (data) => {
    setState({
      data: {
        ...state.data,
        ...data,
      },
    });
  };
  const onNext = (step) => {
    if (step === steps.length - 1) {
      handleSubmit();
    }
  };

  const updateRedux = (isEdit, data) => {
    dispatch(
      editMutationAction("BANK_WALLETS", wallets || [], isEdit ? data[0] : data)
    );
  };

  const handleSubmit = async () => {
    if (state.data.account_number !== state.data.confirm_account_number) {
      dispatch(
        toggleToastAction({
          show: true,
          message: "Account number and confirm account number do not match",
          type: "failure",
        })
      );
      return;
    }
    setLoading(true);
    let toSend = { ...state.data, user: user.id };
    if (toSend?.confirm_account_number) {
      delete toSend?.confirm_account_number;
    }

    let res;
    toSend.currency =
      typeof toSend?.currency === "string"
        ? toSend?.currency
        : `${toSend?.currency?.name}-${toSend?.currency?.currency?.name}`;
    toSend.country =
      typeof toSend?.country === "string"
        ? toSend?.country
        : toSend?.country?.name;
    if (!toSend?.id) {
      res = await ApiCallHandler.send(URLS.CREATE_BANK_WALLETS, "POST", toSend);
    } else {
      res = await ApiCallHandler.send(URLS.UPDATE_BANK_WALLETS, "POST", toSend);
    }
    if (res && res.success) {
      updateRedux(toSend?.id, res?.data);
      setLoading(false);
      props.close();
      dispatch(
        toggleToastAction({
          show: true,
          message: !toSend?.id
            ? "Bank Account created successfully"
            : "Bank Account Updated Successfully",
          type: "success",
        })
      );
    } else {
      dispatch(
        toggleToastAction({
          show: true,
          message: "An error occurred. Try again",
          type: "failure",
        })
      );
    }
  };
  const onCancel = () => {
    props.close();
  };
  const steps = [
    {
      name: "Holder's Information",
      component: (
        <UserInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faUser,
    },
    {
      name: "Bank Information",
      component: (
        <BankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faPiggyBank,
    },
    {
      name: "Verify Information",
      component: (
        <VerifyBankInfo onStateChange={collectFormData} data={state?.data} />
      ),
      icon: faCheckCircle,
    },
  ];
  return (
    <div>
      <Stepper
        variant={"horizontal"}
        steps={steps}
        onNext={onNext}
        state={state}
        onCancel={onCancel}
      />
      {!loading && <Processing text="submitting data ....." />}
    </div>
  );
}
