import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCubes,
  faHandshake,
  faInfoCircle,
  faLongArrowAltRight,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";

import "./userDashboard.css";
import { Table, NoItems } from "@kehillahglobal/ui";
import { connect } from "react-redux";
import Title from "../../components/title/Title";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import {
  getAllDealsAction,
  toggleToastAction,
  toggleModalAction,
  getUserInvitationsAction,
} from "../../store/actions/actions";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import {
  capitalizeMe,
  CLIENT_PREFIX,
  convertNumberToShortForm,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
  hasEmptyFields,
  isEmpty,
} from "../../shared/js/utils";
import { Link } from "react-router-dom";
import Verified from "../../shared/components/verified/Verified";
import ShowActions from "../../shared/components/showActions/ShowActions";
import Processing from "../../shared/components/processing/Processing";

const GREETING_MESSAGE = ["Hello", "HI", "Welcome", "Welcome Back", "Hey"];

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deals: [],
      msg: 0,
      id: "",
      showActions: "",
      screenWidth: window.screen.width,
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ msg: Math.floor(Math.random() * GREETING_MESSAGE.length) });
  }
  getCurrency = () => {
    let { userData } = this.props;
    let currency = getMyCurrencyInfo(userData?.currency);
    return currency;
  };

  showTopTenDeals = (partnerships) => {
    let sorted = partnerships
      .sort((firstItem, secondItem) => secondItem.shares - firstItem.shares)
      .slice(0, 5);
    return sorted || [];
  };

  handleAccept = async (invitation) => {
    this.setState({ loading: true });
    const dataToSend = {
      shares: invitation?.shares,
      deal: invitation?.deal.id,
      deal_invitation_id: invitation?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    let res;
    res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );
    if (res && res.success) {
      this.setState({ loading: false });
      this.props.getDeals();
      this.props.getInvitations();
      this.props.toggleToast({
        show: true,
        message: "Acceptance Successful",
        type: "success",
      });
    } else {
      this.setState({ loading: false });
      this.props.toggleToast({
        show: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  convertMoney = (money, code) => {
    let { rates } = this.props;
    let finalAmount = money / rates[code];
    return finalAmount;
  };

  showConfirmationModal = (item) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Invitation Acceptance Confirmation`,
        children: (
          <div style={{ padding: "10px 15px" }}>
            Do you wish to accept this deal invitation from
            {item?.deal?.enterprise?.name} ?
          </div>
        ),
        themeColor: "var(--app-theme)",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => this.handleAccept(item),
        },
      },
    });
  };

  convertDistributionFigures = (distribution) => {
    let { rates } = this.props;
    let currency = getMyCurrencyInfo(distribution?.deal?.enterprise?.currency);
    return {
      amount: distribution?.amount_to_receive * getRate(currency?.code, rates),
      charges:
        distribution?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: distribution?.full_amount * getRate(currency?.code, rates),
    };
  };

  handleViewDetails = (invitation) => {
    let userId = this.props?.match?.params?.userID
    this.props.history.push(
      `/client/${userId}/deal/response/action/${invitation?.id}`
    );
  };
  getAmountPlacedInDeal = (deal, code) => {
    let shares = deal.shares;
    let pps = this.convertMoney(deal.deal.price_per_share, code);
    let amount = shares * pps;
    return amount;
  };
  prepareTableData = () => {
    let { deals } = this.props;
    let sorted = this.showTopTenDeals(deals);
    return sorted.map((deal,index) => {
      let currency = getMyCurrencyInfo(deal?.deal?.enterprise?.currency);
      let amount = this.getAmountPlacedInDeal(deal, currency?.code)?.toFixed(2);
      return [
        <p>{index+1}</p>,
        <p>{deal?.deal?.enterprise?.name}</p>,
        <p>{deal?.deal?.name}</p>,
        <p>{deal?.shares || 0}</p>,
        <p>
          {currency.symbol} {convertNumberToShortForm(amount)}{" "}
        </p>,
        <p>
          {(deal?.shares /deal?.deal?.shares * 100).toFixed(2)}{" "} %
        </p>,
      ];
    });
  };

  toggleShowMoreOptions = (item) => {
    if (!item?.id) {
      this.setState({
        showActions: false,
        id: "",
      });
    } else {
      this.setState({
        showActions: true,
        id: item?.id,
      });
    }
  };
  prepareTableDataForInvite = () => {
    let { unaccepted_invitations, userData } = this.props;
    let userCurrency = getMyCurrencyInfo(userData?.currency);
    return unaccepted_invitations.slice(0, 5).map((item) => {
      let currency = getMyCurrencyInfo(item?.deal?.enterprise?.currency);

      return [
        <p>{item?.deal?.enterprise?.name}</p>,
        <p>{item?.deal.name}</p>,
        <p>{item?.shares} </p>,
        <p>
          {currency?.symbol} {item?.deal?.price_per_share} (
          {userCurrency?.symbol}{" "}
          {convertNumberToShortForm(
            this.convertMoney(
              item?.deal?.price_per_share,
              currency?.code
            )?.toFixed(2)
          )}
          )
        </p>,
        <p>{getMeaningfulDate(item?.deal?.close_date)} </p>,

        <ShowActions
          onView={this.handleViewDetails}
          onApproved={this.showConfirmationModal}
          item={item}
          id={this.state.id}
          toggleShowMoreOptions={this.toggleShowMoreOptions}
          show={this.state.showActions}
        />,
      ];
    });
  };
  prepareDistributionData = (distributions) => {
    return distributions?.map((item, index) => {
      let figures = this.convertDistributionFigures(item);
      return [
        <p>{index+1}</p>,
        <p>{getMeaningfulDate(item?.date)}</p>,
        <p>{item?.deal?.enterprise?.name}</p>,
        <p>{item?.deal?.name}</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(figures?.amount?.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "tomato" }}>
          {convertNumberToShortForm(figures?.charges.toFixed(2))}{" "}
        </p>,
        <p style={{ color: "grey" }}>
          {convertNumberToShortForm(figures?.fullAmount?.toFixed(2))}
        </p>,
        <p>{item?.payment_status}</p>,
      ];
    });
  };

  showIntro = () => {
    let { userData, stats } = this.props;

    return (
      <div id="user-dash-intro">
        <div id="intro-txt-container" className="move-down-small">
          <p>
            {GREETING_MESSAGE[this.state.msg]}{" "}
            {capitalizeMe(userData?.first_name)}
            {""}
            {userData?.is_verified ? (
              <Verified bg="green" height={"25px"} width={"25px"} />
            ) : null}
            !
          </p>
          <span>Here is an overview of what has been happening. </span>
        </div>

        <div id="intro-stats-container">
          <div id="stat-item-container" className="move-down-small">
            <span>Total Amount in Deals</span>
            {!hasEmptyFields(stats) ? (
              <p>
                {this.getCurrency()?.symbol}{" "}
                {convertNumberToShortForm(
                  stats?.total_in_partnership?.toFixed(2) || parseFloat(0.0)
                )}
              </p>
            ) : (
              <h4 className="computing">Computing....</h4>
            )}
          </div>
          <div style={{ marginRight: 15 }}>
            <FontAwesomeIcon icon={faLongArrowAltRight} color="grey" />
          </div>
          <div className={`everyday-flex right-of-title-box `}>
            <h6>Total Distributions</h6>
            {!hasEmptyFields(stats) ? (
              <h1>
                {this.getCurrency()?.symbol}{" "}
                {convertNumberToShortForm(
                  stats?.completed_distributions?.toFixed(2) || parseFloat(0.0)
                )}
              </h1>
            ) : (
              <h4 className="computing">Computing....</h4>
            )}
          </div>
        </div>
      </div>
    );
  };
  getCurrentMonth = () => {
    let d = new Date();
    let month = d.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    return month;
  };
  getCurrentMonthWithDate = (date) => {
    let d = new Date(date);
    let month = d.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    return month;
  };

  showCardInfo = () => {
    let { deals, stats } = this.props;
    let { screenWidth } = this.state;
    let myDeals = deals.filter((item) => item.is_approved === true) || [];
    return (
      <div
        className={`dash-card-container ${
          screenWidth > 480 ? "elevate-float " : ""
        }`}
      >
        <div
          className={`dash-card-item ${
            screenWidth <= 480 ? "elevate-float mb" : ""
          }`}
        >
          <div className="dash-card-icon-container">
            <FontAwesomeIcon icon={faHandshake} />
          </div>
          <div className="dash-card-txt-container">
            <p>Deals</p>
            <span>
              {myDeals?.length < 10 ? "0" + myDeals?.length : myDeals?.length}
            </span>
          </div>
        </div>
        <div
          className={`dash-card-item ${
            screenWidth <= 480 ? "elevate-float mb" : ""
          }`}
        >
          <div
            className="dash-card-icon-container"
            style={{ backgroundColor: "var(--app-theme-hover)" }}
          >
            <FontAwesomeIcon icon={faMoneyBillWave} />
          </div>
          <div className="dash-card-txt-container">
            <p>Pending Distributions</p>
            <span className="profit-items">
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(
                stats?.pending_distributions?.toFixed(2)
              ) || 0.0}{" "}
            </span>
          </div>
        </div>
        <div
          className={`dash-card-item ${
            screenWidth <= 480 ? "elevate-float mb" : ""
          }`}
        >
          <div className="dash-card-icon-container">
            <FontAwesomeIcon icon={faCoins} />
          </div>
          <div className="dash-card-txt-container">
            <p>{this.getCurrentMonth()} Distributions</p>
            <span className="profit-items">
              {this.getCurrency()?.symbol}{" "}
              {convertNumberToShortForm(
                stats?.current_month_distributions?.toFixed(2)
              ) || 0.0}{" "}
            </span>
          </div>
        </div>
        <div
          className={`dash-card-item ${
            screenWidth <= 480 ? "elevate-float mb" : ""
          }`}
        >
          <div
            className="dash-card-icon-container"
            style={{ backgroundColor: "var(--app-theme-active)" }}
          >
            <FontAwesomeIcon icon={faCubes} />
          </div>
          <div className="dash-card-txt-container">
            <p>Total Shares</p>
            <span className="profit-items">
              {convertNumberToShortForm(
                parseFloat(stats?.total_shares || 0)?.toFixed(0)
              )}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  };

  showTopDealsAndInvites = () => {
    let { deals } = this.props;
    let { unaccepted_invitations } = this.props;
    return (
      <div className="dashboard-deals-invites-container">
        {unaccepted_invitations?.length ? (
          <div className="dash-invites-container elevate-float invitation-list">
            <Title>Invitations</Title>
            {this.props.unaccepted_invitations?.length > 0 ? (
              <Table
                columns={[
                  "Enterprise",
                  "Deal",
                  "Shares",
                  "PPS",
                  "closes on",
                  "\t",
                ]}
                data={this.prepareTableDataForInvite()}
                hoverAnimation={false}
              />
            ) : (
              <NoItems text="You have no Invitations" />
            )}
          </div>
        ) : null}

        <div className="top-investors-container elevate-float deals-lists">
          <Title>Top Deals</Title>
          {deals?.length > 0 ? (
            <Table
              columns={["#","Enterprise", "Deal", "Shares", "Amount", "Percentage"]}
              data={this.prepareTableData()}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="You have no Deals" />
          )}
        </div>
      </div>
    );
  };
  showPaymentsAndBarChart = () => {
    let { distributions } = this.props;
    return (
      <div className="dashboard-transaction-bar-container">
        <div className="top-investors-container">
          <Title>Recent Distributions</Title>
          {distributions.length > 0 ? (
            <Table
              columns={[
                "#",
                "Date",
                "Sender",
                "Deal",
                `Amount (${this.getCurrency()?.symbol})`,
                `Charges (${this.getCurrency()?.symbol})`,
                `Full Amount  (${this.getCurrency()?.symbol})`,
                `Status`,
              ]}
              data={this.prepareDistributionData(distributions)}
              hoverAnimation={false}
              rootClass="distributions-table"
            />
          ) : (
            <NoItems text="You have no distributions" />
          )}
        </div>
      </div>
    );
  };

  showYear = () => {
    return new Date().getFullYear();
  };

  showSetPaymentPreferenceNotification = () => {
    let { paymentPrefs: prefs } = this.props;
    let { screenWidth } = this.state;

    if (!isEmpty(prefs)) return;

    return (
      <div
        className={`set-payment-pref-container ${
          screenWidth <= 480 ? "elevate-float " : ""
        }`}
      >
        <div style={{ display: "flex" }}>
          <div id="info-icon-container">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>

          <div id="notification-body-container">
            <span>Payment Preference</span>
            <p>
              It looks like you have not configured your payment preferences.
              Payment preference is a requirement for distributions.{" "}
              <Link to={`${CLIENT_PREFIX}/wallets`}> Click</Link> to configure.{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
        {this.showIntro()}

        {this.showSetPaymentPreferenceNotification()}
        {this.showCardInfo()}
        {this.showTopDealsAndInvites()}
        {this.showPaymentsAndBarChart()}

        {this.state.loading ? <Processing /> : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals:state?.myDeals && state?.myDeals?.filter
        ? state?.myDeals?.filter((deal) => deal.is_approved === true)
        : [],
    unaccepted_invitations:
      state?.invitations && state?.invitations?.filter
        ? state?.invitations?.filter(
            (invitation) => invitation.has_accepted === null
          )
        : [],
    stats: state.dashboardStats,
    distributions: state.myDistributions,
    userData: state.userData,
    paymentPrefs: state.paymentElections,
    rates: state.exchangeRates,
    allDeals: state.myDeals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDeals: getAllDealsAction,
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      getInvitations: getUserInvitationsAction,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDashboard)
);
