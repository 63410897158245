import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./DistributionsDetail.css";
import Title from "../../../components/title/Title";
import { Table, NoItems } from "@kehillahglobal/ui";
import {
  capitalizeMe,
  convertNumberToShortForm,
  getFileType,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
  parseJSON,
} from "../../../shared/js/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { toggleImagePreviewAction } from "../../../store/actions/actions";
import {
  IMAGE_TYPES,
  DISTRIBUTION_TYPES,
  ACCOUNT_TYPES,
} from "../../../shared/js/Konstants";
import BackButton from "../../../shared/components/back-button/BackButton";

class DistributionsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distribution: {},
    };
  }
  static getDerivedStateFromProps(props, state) {
    let { id } = props?.match?.params;
    if (id) {
      let distribution = props?.distributions?.find((item) => item?.id === id);
      return {
        distribution,
      };
    }
    return;
  }

  getRemended = () => {
    let { distribution } = this.state;
    let remended = distribution?.full_amount - distribution?.amount_to_receive;
    return remended;
  };

  convertMoney = (money = 0) => {
    let { rates, distribution } = this.props;
    let currency = getMyCurrencyInfo(distribution?.deal?.enterprise?.currency);
    let finalAmount = money * getRate(currency?.code, rates);
    return finalAmount;
  };

  getFileName = (file) => {
    if (!file) return;
    let arr = file?.split("/");
    let last = arr?.[arr?.length - 1]?.split("?");
    return last[0];
  };

  showCommentFile = (file) => {
    if (!file) return;
    const fileType = getFileType(file);
    if (IMAGE_TYPES.includes(fileType))
      return (
        <img
          src={file}
          alt="  "
          onClick={() => {
            this.props.toggleImagePreview({
              show: true,
              image: file,
            });
          }}
        />
      );

    return (
      <div className="comment-file-container ">
        <div
          className="elevate-float file-wrapper"
          onClick={() => window.open(file, "_blank")}
        >
          <FontAwesomeIcon icon={faFileAlt} fontSize="7rem" color="maroon" />
        </div>
        <p
          style={{ color: "blue" }}
          onClick={() => window.open(file, "_blank")}
        >
          {this.getFileName(file)}
        </p>
      </div>
    );
  };

  showComment = (comment) => {
    return (
      <div className="comment-item-container " style={{ position: "relative" }}>
        <div style={{ display: "flex" }}>
          <div id="commenter-img-container">
            <img
              src={comment?.sender?.image || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>
          <div id="commenter-info">
            <h4>{capitalizeMe(comment?.sender?.first_name)}</h4>
            <small>{getMeaningfulDate(comment?.date)}</small>
          </div>
        </div>
        <div id="comment-body">
          <p>{comment?.body || ""}</p>
          <div id="comment-file-container">
            {comment?.attachment
              ? this.showCommentFile(comment?.attachment)
              : null}
          </div>
        </div>
      </div>
    );
  };

  calculateAmountFromPercentage = (percentage, amount = 1) => {
    let _amount = (percentage / 100) * amount;
    let final = _amount;
    return final;
  };

  getPercentage = (pref) => {
    let distribution = pref?.distribution;
    let total = 0;
    distribution = parseJSON(distribution);
    if (distribution?.distribution === DISTRIBUTION_TYPES.PERCENTAGE)
      return distribution?.percentage;

    this.props?.prefs?.forEach((election) => {
      let dist = parseJSON(election.distribution);
      if (dist.distribution === DISTRIBUTION_TYPES.PERCENTAGE) {
        total += Number(dist.percentage);
      }
    });

    return (100 - total).toFixed(2);
  };

  prepareTableData = () => {
    let { distribution } = this.state;
    return this.props?.prefs?.map((item) => {
      let currency = getMyCurrencyInfo(item?.account?.currency);
      return [
        <p>{item?.payment_type}</p>,
        <p>{item?.account?.country}</p>,
        <p>{currency?.code} </p>,
        <p>{item?.account_type}</p>,
        <p>
          {capitalizeMe(
            item?.account_type === ACCOUNT_TYPES.MOBILE
              ? item?.account?.registered_name
              : item?.account?.bank_name
          )}
        </p>,
        <p>
          *****{" "}
          {item?.account_type === ACCOUNT_TYPES.MOBILE
            ? item?.account?.phone_number.substring(5, 10)
            : item?.account?.account_number.substring(10, 14)}
        </p>,
        <p>{this.getPercentage(item)} %</p>,
        <p className="i-am-money">
          {convertNumberToShortForm(
            this.convertMoney(
              this.calculateAmountFromPercentage(
                this.getPercentage(item),
                distribution?.amount_to_receive
              )
            )?.toFixed(2)
          )}
        </p>,
      ];
    });
  };

  convertDistributionFigures = () => {
    let { rates } = this.props;
    let { distribution } = this.state;
    let currency = getMyCurrencyInfo(distribution?.deal?.enterprise?.currency);
    return {
      amount: distribution?.amount_to_receive * getRate(currency?.code, rates),
      charges:
        distribution?.transaction_charges * getRate(currency?.code, rates),
      fullAmount: distribution?.full_amount * getRate(currency?.code, rates),
    };
  };
  render() {
    let { user } = this.props;
    let { distribution } = this.state;
    let currency = getMyCurrencyInfo(user?.currency);
    let figures = this.convertDistributionFigures();
    return (
      <div className="distribution-detail-root">
        <div style={{ marginBottom: 20 }}>
          <BackButton />
        </div>
        <div className="top-card">
          <div id="feed-card-logo-and-name-container">
            <div id="distribution-company-det">
              <h2>{distribution?.deal?.enterprise?.name}</h2>
              <br />
              <p>{distribution?.deal?.name} </p>
            </div>
          </div>

          <div className="total-received-container">
            <p className="total-received-title">To Receive</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2>{convertNumberToShortForm(figures?.amount?.toFixed(2))}</h2>
            </div>
          </div>

          <div>
            <p className="total-received-title pending">Remaining</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2>
                {convertNumberToShortForm(
                  this.convertMoney(this.getRemended())?.toFixed(2)
                ) || 0.0}
              </h2>
            </div>
          </div>
          <div>
            <p className="total-received-title">Charges</p>

            <div className="received-money-container">
              <p>{currency?.symbol}</p>
              <h2 style={{ color: "#EC7063" }}>
                {convertNumberToShortForm(figures?.charges?.toFixed(2)) || 0.0}
              </h2>
            </div>
          </div>
        </div>

        <div className="payment-distribution-container">
          <Title>Distribution</Title>
          {this.props?.prefs?.length < 1 ? (
            <NoItems text="Please check add a payment Preference" />
          ) : (
            <>
              <Table
                columns={[
                  "Payment Type",
                  "Country",
                  "Currency",
                  "Account Type",
                  "Account",
                  "Account Number",
                  "percentage",
                  `Amount (${currency?.symbol})`,
                ]}
                data={this.prepareTableData()}
                hoverAnimation={false}
                rootClass="pref-table"
              />
            </>
          )}
        </div>

        <div className="distribution-receipt-container">
          {distribution?.receipt && <Title> Distribution Receipt </Title>}
          {distribution?.receipt && (
            <span
              onClick={() => window.open(distribution?.receipt, "_blank")}
              style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
            >
              View Distribution Receipt
            </span>
          )}
        </div>

        <div className="distribution-comments-container">
          <div className="comments-list-container">
            <Title className="comments-header"> Distribution Updates </Title>
            <div className="divider"></div>
            {distribution && distribution?.comments ? (
              distribution?.comments?.map((comment) =>
                this.showComment(comment)
              )
            ) : (
              <NoItems text="No comments yet" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    distributions: state.myDistributions,
    prefs: state.paymentElections,
    user: state.userData,
    rates: state.exchangeRates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleImagePreview: toggleImagePreviewAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionsDetail);
