import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModalAction } from "../../store/actions/actions";
import MyDeals from "./my deals/MyDeals";
import MyInvitations from "./my invitations/MyInvitations";
import { TabView } from "@kehillahglobal/ui";
import "./user-deals.css";
import SearchBar from "../../shared/components/SearchBar/SearchBar";
import DealsSearchResults from "./search-results/DealsSearchResults";

class DealDetailWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changer: "my-deals", isSearching: false, results: [] };
  }

  TABS = [
    {
      name: "Deals",
      id: "my-deals",
      component: (
        <MyDeals switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
    {
      name: "Invitations",
      id: "my-invitations",
      component: (
        <MyInvitations switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
  ];

  updateSearchStatus = (result, queryText, searchFilters) => {
    if(queryText){
      this.setState({
        isSearching: true,
        results: result,
      });
    }
    else{
       this.setState({ results: [], changer: "my-deals" }, () =>
         this.setState({ isSearching: false })
       );
    }
  };
  render() {
    let { data } = this.props;
    let { isSearching, results } = this.state;
    return (
      <div className="deals-wrapper-root">
        <div>
          <SearchBar
            data={data?.map((item) => ({...item, deal_name: item.deal?.name, enterprise_name: item.deal?.enterprise?.name}))}
            searchFields={["shares", "deal_name", "enterprise_name"]}
            updateSearchStatus={this.updateSearchStatus}
          />
        </div>
        {isSearching && results ? (
          <DealsSearchResults results={results} />
        ) : (
          <TabView
            data={this.TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            onMount={(changer) => this.setState({ changer })}
            defaultTab={this.state.changer}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let deals = state.myDeals || [];
  let invitations = state.myInvitations|| [];
  return {
    data: [...deals, ...invitations],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DealDetailWrapper);
