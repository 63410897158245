import React from "react";
import { useEffect, useState } from "react";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { Table, NoItems } from "@kehillahglobal/ui";
import Processing  from '../../../../shared/components/processing/Processing';
import {
  convertNumberToShortForm,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
} from "../../../../shared/js/utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Expenses(props) {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  let rates = useSelector((state) => state.exchangeRates);
  let user = useSelector((state) => state.userData);
  let params = useParams();

  useEffect(() => {
    setLoading(true);
    ApiCallHandler.send(URLS.LIST_DEAL_EXPENSE, "POST", {
      deal: params?.dealID,
    }).then((res) => {
      setLoading(false);
      if (res && res?.success) {
      setExpenses(res?.data);
      }
    });
  }, [params?.dealID]);

  const convertMoney = (money, code) => {
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  const getUserCurrency = getMyCurrencyInfo(user?.currency);
  const prepareTableData = (expenses) => {
    let tableData = [];
    expenses.map((item, index) => {
      return tableData.push([
        <p>{index + 1}</p>,
        <p>{item?.expense_type?.name}</p>,
        <p>
          {convertNumberToShortForm(parseFloat(item?.amount)?.toFixed(2))} (
          {getUserCurrency?.symbol}{" "}
          {convertNumberToShortForm(
            parseFloat(convertMoney(item?.amount, currency?.code)?.toFixed(2))
          )}
          )
        </p>,
        <p>{getMeaningfulDate(item?.transaction_date?.split("T")[0])}</p>,
      ]);
    });

    return tableData;
  };

  const currency = getMyCurrencyInfo(props?.deal?.enterprise?.currency);

  return (
    <div>
      {loading ? (
        <Processing text="Loading..." />
      ) : (
        <div style={{ borderRadius: 10 }}>
          {expenses?.length ? (
            <Table
              columns={[
                '#',
                "Expense Type",
                `Amount (${currency?.symbol})`,
                "Transaction Date",
              ]}
              data={prepareTableData(expenses)}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No expense has been added for this deal" />
          )}
        </div>
      )}
    </div>
  );
}
