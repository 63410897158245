import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {NoItems} from "@kehillahglobal/ui";
import FeedsCard from "../../../feeds/feeds-card/FeedsCard";
import { withRouter } from "react-router-dom";

class DealDetailFeeds extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deal:{}
    }
  }
  static getDerivedStateFromProps(props, state) {
    let {dealID} = props?.match?.params;
    if(dealID){
      let deal = props?.deals?.find(item =>item?.deal.id === dealID);
      return {
        deal
      }
    }
    return 
  }

  reCreateReportData = (deal) => {
    let obj = {};
    let feeds = [];

    deal?.deal?.feeds?.forEach((rep) => {
      obj = {
        ...rep,
        deal: {
          name: deal?.deal?.name,
          enterprise: {
            name: deal?.deal?.enterprise?.name,
            logo: deal?.deal?.enterprise?.logo,
          },
        },
      };

      feeds.push(obj);
    });

    return feeds;
  };

  render() {
    let { deal } = this.state;
    return (
      <div id="opp-detail-feed-root">
        <div className="list-root">
          {deal?.deal?.feeds ? (
            this.reCreateReportData(deal)?.map((feed, index) => (
              <FeedsCard
                key={index}
                feed={feed}
              />
            ))
          ) : (
            <NoItems text="No Feed Added by deal  admins" />
          )}
        </div>


      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state.myDeals
};
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(DealDetailFeeds))
