import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {NoItems } from "@kehillahglobal/ui";
import DealCard from "../deal card/DealCard";
import "../user-deals.css";

class MyInvitations extends Component {
  render() {
    const items = this.props.invitations;
    return (
      <div className="">
        <div className="user-deal-items-wrapper">
          {items?.length > 0 ? (
            items.map((item) => <DealCard deal={item} fromInvitation={true} />)
          ) : (
            <div id="no-deals-container">
              <NoItems title="No Deals" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const freshInvites = state.invitations?.filter((item) =>item?.has_accepted === null
  );
  const acceptedInvites = state.myDeals?.filter(
    (deal) => deal.is_approved !==true
  );

  return {
    invitations: [...freshInvites, ...acceptedInvites],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInvitations);
