import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, NoItems } from "@kehillahglobal/ui";
import Header from "../../../../components/header/Header";
import { capitalizeMe, CLIENT_PREFIX, parseJSON } from "../../../../shared/js/utils";
import { withRouter } from 'react-router-dom';
import { ASSET_MANAGER_WORK_STATUS } from "../../../../shared/js/Konstants";

class DealAssets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deal:{}
    }
  }
  static getDerivedStateFromProps(props, state) {
    let {dealID} = props?.match?.params;
    if(dealID){
      let deal = props?.deals?.find(item =>item?.deal.id === dealID);
      return {
        deal
      }
    }
    return 
  }


  parseData = (data) => {
    if (typeof data === "string") {
      return parseJSON(data);
    }
    return data;
  }
  
  getActiveManager = (managers) => {
    if (!managers) return "No Manager";
    let activeManager = managers.find(
      (manager) =>
        this.parseData(manager?.user?.info)?.status ===
          ASSET_MANAGER_WORK_STATUS.WORKING || ASSET_MANAGER_WORK_STATUS.WORKING
    );

    let name = activeManager
      ? capitalizeMe(activeManager?.user?.first_name) +
        " " +
        capitalizeMe(activeManager?.user?.last_name)
      : "No active manager";
    return {
      name: name,
      status: activeManager
        ? this.parseData(activeManager?.user?.info)?.status
        : "Not set",
    };
  };

  handleShowDetail = (item) => {
    let dealID = this.props?.match?.params?.dealID;
    this.props.history.push(CLIENT_PREFIX+`/deals/${dealID}/assets/` + item.id);
  };

  prepareTableData = (assets) => {
    return assets.map((asset, index) => {
      return [
        <p>{index+1}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.name}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.asset_type?.name}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{asset?.identification_number}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{this.getActiveManager(asset?.managers)?.name || "Not Set"}</p>,
        <p onClick={() => this.handleShowDetail(asset)}>{this.getActiveManager(asset?.managers)?.status || "Not Set"}</p>,
      ];
    });
  };
  render() {
    let assets = this.state?.deal?.deal?.assets;
    return (
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <Header
          title="Deal Assets"
          subtitle="A list of assets linked to this deal"
        />
        {assets ? (
          <Table
            columns={[
              "#",
              "Name",
              "Type",
              "Identification Number",
              "Manager",
              "Manager Status",
            ]}
            data={this.prepareTableData(assets)}
            hoverAnimation={false}
            rootClass="opp-assets-table"
          />
        ) : (
          <NoItems text="No Linked Assets" />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    deals: state.myDeals
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DealAssets)
);
