import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleSidePane } from "../../store/actions/actions";
import { MENU, BOTTOM_MENU } from "./values";
import { Sidebar } from "@kehillahglobal/ui";
import "./userPageWrapper.css";
import { withRouter } from "react-router-dom";
import { capitalizeMe } from "../../shared/js/utils";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
const SHRINK_SIDE_RATIO = 71;
const EXPANDED_SIDE_RATIO = 271;
export const USER_DETAILS = {
  userName: "Abdullai Tahiru",
  // role: " ",
  // // companyName: "Kehillah Global",
  // companyLogo:
  //   "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
};

class UserPageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { isUntouched: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.isUntouched &&
      (state.sideRatio !== props.sideRatio ||
        state.contentRatio !== props.contentRatio)
    ) {
      return {
        sideRatio: props.sideRatio,
        contentRatio: props.contentRatio,
        isUntouched: false,
      };
    }

    return null;
  }
  getContentRatio() {
    var { sideRatio } = this.state;
    sideRatio = sideRatio || 0;
    var contentRatio = 10 - sideRatio;
    return [sideRatio, contentRatio];
  }

  handleSidebarStateChange = (state) => {
    if (!state.shrink && this.state.sideRatio !== EXPANDED_SIDE_RATIO)
      this.setState({ sideRatio: EXPANDED_SIDE_RATIO });
    else if (state.shrink && this.state.sideRatio !== SHRINK_SIDE_RATIO)
      this.setState({ sideRatio: SHRINK_SIDE_RATIO });
  };
  shortenName = (name) => {
    if (!name) return "";
    return name?.charAt(0)?.toUpperCase() + ".";
  };
  render() {
    const { sideRatio } = this.state;
    const { sidePane, user } = this.props;
    const userDetails = {
      userName:
        user?.username ||
        capitalizeMe(user?.first_name) +
          " " +
          this.shortenName(user?.last_name),
      userImage: user?.image || "https://i.ibb.co/0DRHM5M/user5.jpg",
    };

    return (
      <div
        className="root-wrapper"
        style={{
          "--side-ratio": `${sideRatio}px`,
        }}
      >
        <div>
          <Sidebar
            menu={MENU}
            dark
            bottomMenu={BOTTOM_MENU}
            userDetails={userDetails}
            onShrinkBtnClick={() =>
              this.setState({ sideRatio: SHRINK_SIDE_RATIO })
            }
            onStateChange={this.handleSidebarStateChange}
            onTabItemClick={(e, { link }) => {
              if (!link) {
                const iAmSureIWantToLogOut = window.confirm(
                  "Are you sure you want to sign out?"
                );
                if (iAmSureIWantToLogOut) {
                  ApiCallHandler.send(URLS.LOGOUT, "POST", {}).then((res) => {
                    if (res.success) {
                      window.location.href = "/";
                    }
                  });
                }
              }
              this.props.history.push(link);
            }}
            verified={user?.is_verified ? true : false}
          />
        </div>
        <div className="children-container">
          {sidePane?.on && (
            <div className="side-pane elevate-float animate-side-pane"></div>
          )}
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

UserPageWrapper.defaultProps = {
  sideRatio: SHRINK_SIDE_RATIO,
};

const mapStateToProps = (store) => {
  return {
    sidePane: store.sidePane,
    user: store.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSidePane: toggleSidePane,
    },
    dispatch
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPageWrapper)
);
