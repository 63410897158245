import reduxThunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers/combined";
import { createStore, applyMiddleware, compose } from "redux";

const store = createStore(
  reducers,

  compose(
    JSON.parse(process.env.REACT_APP_IS_LOCAL || false)
      ? applyMiddleware(reduxThunk, logger)
      : applyMiddleware(reduxThunk)
  )
);

export default store;
