import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { NoItems } from "@kehillahglobal/ui";
import DealCard from "../deal card/DealCard";
import '../user-deals.css'



class MyDeals extends Component {
  render() {
    const items = this.props.myDeals;
    return (
      <div className="">
        <div className="user-deal-items-wrapper">
          {items?.length > 0 ? (
            items.map((item) => <DealCard inMyDeals={true} deal={item}/>)
          ) : (
            <div id="no-deals-container">
              <NoItems title="No Deals" />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state =>{
     return {
       myDeals: state?.myDeals && state?.myDeals?.filter ? state.myDeals?.filter(
         (deal) => deal.is_approved === true
       ) : [],
     };
} 

const mapDispatchToProps = dispatch =>{
     return bindActionCreators({},dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(MyDeals);