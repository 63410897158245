/* eslint-disable import/no-anonymous-default-export */
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ApiCallHandler from "../shared/js/ApiCallHandler";
import URLS from "../shared/js/urls";
import {
  toggleModalAction,
  toggleToastAction,
  getAllDealsAction,
  getUserInvitationsAction,
} from "../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {Button} from "@kehillahglobal/ui";
import "./DealResponse.css";
import Title from "../components/title/Title";
import { Redirect } from "react-router-dom";
import Processing from "../shared/components/processing/Processing";
import { capitalizeMe } from "../shared/js/utils";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default () => {
  const [dataGotten, setDataGotten] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData);

  const history = useHistory();
  const params = useParams()
  const userID = params?.userID;


  useEffect(() => {
    if (user?.id) {
      const id = params?.invitationId;
      ApiCallHandler.send(URLS.GET_DEAL_INVITATION_INFO, "POST", {
        id: id,
      }).then((res) => {
        if (res && res.success) {
          setDataGotten(res?.data);
          if (res?.data?.has_accepted) {
           window.location.href = `/client/${userID}/dashboard`;
          }
        } else {
          setDataGotten(res);
        }
      });
    }
  }, [dispatch, history, params?.invitationId, userID, user?.id]);

  const handleAcceptOffer = async () => {
  
    setLoading(true);
    const dataToSend = {
      shares: dataGotten?.shares,
      deal_id: dataGotten?.deal?.id,
      deal_invitation_id: dataGotten?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    const res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );

    if (res.success) {
      dispatch(toggleModalAction({ show: false }));
      setLoading(false);
      dispatch(
        toggleToastAction({
          show: true,
          message: "Success",
          type: "success",
        })
      );
      dispatch(getAllDealsAction());
      dispatch(getUserInvitationsAction());

      window.location.href = `/client/${userID}/dashboard`;
    } else {
      dispatch(toggleModalAction({ show: false }));
      setLoading(false);
      dispatch(
        toggleToastAction({
          show: true,
          message: "Acceptance Failed Please Try Again",
          type: "failure",
        })
      );
    }
  };

  const handleRejectOffer = async () => {
    setLoading(true);
    const dataToSend = {
      id: dataGotten?.id,
      has_accepted: "False",
      has_responded: "True",
      is_archived: "True",
    };
    const res = await ApiCallHandler.send(
      URLS.UPDATE_DEAL_INVITATION,
      "POST",
      dataToSend
    );

    if (res.success) {
      dispatch(toggleModalAction({ show: false }));
      dispatch(getUserInvitationsAction());
      setLoading(false);
      dispatch(
        toggleToastAction({
          show: true,
          message: "Success",
          type: "success",
        })
      );
       window.location.href = `/client/${userID}/dashboard`;
    } else {
      dispatch(toggleModalAction({ show: false }));
      setLoading(false);
      dispatch(
        toggleToastAction({
          show: true,
          message: "Acceptance Failed Please Try Again",
          type: "failure",
        })
      );
    }
  };

  const renderInvitationNotFoundMessage = () => {
    return (
      <div className="elevate-float invitation-not-found-message-container">
        <div>
          <h2>Hi 👋 {capitalizeMe(user?.first_name)}.</h2>
        </div>
        <div className="message-body-container">
          <span>
            We are sorry to inform you that your invitation has expired or has
            been turned down. Please contact the enterprise admin to resend you
            a new invitation. In the meantime, you can explore our{" "}
            <Link to="/">Platform</Link> as you wait for your invitation.
          </span>
          <p>Thank You 😊</p>
        </div>
      </div>
    );
  };

  const showConfirmationMassage = () => {
    return dataGotten && dataGotten?.id ? (
      <div className="elevate-1 response-wrapper ">
        <center id="res-name-section">
          <Title>Hi {dataGotten?.name || " "},</Title>
        </center>
        <div id="response-intro-container">
          <p>
            You have been invited by{" "}
            <strong>{dataGotten?.deal?.enterprise?.name}</strong> and offered{" "}
            <strong>{dataGotten?.shares || " "} shares</strong> which will cost
            you{" "}
            <strong style={{ color: "green" }}>
              GHC {dataGotten?.shares * dataGotten?.deal?.price_per_share || ""}{" "}
            </strong>
            In the deal with the details below
          </p>
        </div>

        <div id="res-about-container">
          <Title>{dataGotten?.deal?.name || ""}</Title>
          <div
            dangerouslySetInnerHTML={{
              __html: dataGotten?.deal?.about || "",
            }}
          ></div>
        </div>

        <div id="response-btns-container">
          <Button
            theme="danger"
            onClick={() => {
              dispatch(
                toggleModalAction({
                  show: true,
                  props: {
                    title: "Decline Invitation",
                    children: (
                      <div style={{ padding: "15px 10px" }}>
                        <p>
                          Do you wish to decline{" "}
                          <strong> {dataGotten?.deal?.name}</strong> deal?.
                        </p>
                      </div>
                    ),
                    themeColor: "tomato",
                    cancel: true,
                    okay: {
                      text: "Decline",
                      function: () => handleRejectOffer(),
                    },
                  },
                })
              );
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ fontSize: "2rem", color: "red" }}
            />
            Reject
          </Button>
          <Button
            theme="success"
            onClick={() => {
              dispatch(
                toggleModalAction({
                  show: true,
                  props: {
                    title: "Accept Invitation",
                    children: (
                      <div style={{ padding: "10px 10px" }}>
                        <p>
                          Accept <strong> {dataGotten?.deal?.name}</strong>{" "}
                          deal.
                        </p>
                      </div>
                    ),
                    themeColor: "var(--app-theme)",
                    cancel: true,
                    okay: {
                      text: "Yes I Accept",
                      function: () => handleAcceptOffer(),
                    },
                  },
                })
              );
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "2rem", color: "green" }}
            />
            Accept
          </Button>
        </div>
      </div>
    ) : dataGotten.error ? (
      renderInvitationNotFoundMessage()
    ) : (
      <Processing text={"loading data "} />
    );
  };

  if (user?.has_accepted_tcs) {
    return (
      <div className="opp-response-main-container">
        {showConfirmationMassage()}
        {loading && <Processing text={"submitting data...."} />}
      </div>
    );
  } else {
    if (
      (user && user?.has_accepted_tcs === false) ||
      user?.has_accepted_tcs === null
    ) {
      return (
        <Redirect
          to={{
            pathname: "/country-and-currency-selection",
            state: { invitationId: params?.invitationId },
          }}
        />
      );
    }
    return (
      <Processing text={"loading data "} />
    );
  }
};

