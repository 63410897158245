import React from "react";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Searchbar.css";
import { CheckboxGroup, Textbox } from "@kehillahglobal/ui";
import { parseJSON } from "../../js/utils";


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryText: "",
      showFilters: false,
      searchFilters: [],
    };
  }
  search = () => {
    let { queryText, searchFilters } = this.state;
    let {updateSearchStatus} = this.props;
        
    let filtered = this.getDealsMatchingFilters()?.filter(
      (deal) => {
        return this.props.searchFields?.some((item) =>
          deal[item]
            ?.toString()
            ?.toLowerCase()
            ?.includes(queryText?.toString()?.toLowerCase())
        );
      }
    );

    updateSearchStatus && updateSearchStatus(filtered, queryText, searchFilters);
  };

  getDealsMatchingFilters = () => {
    const { data } = this.props;
    let { searchFilters, queryText } = this.state;
    
  if (!searchFilters.length && !queryText ) return [];

  if (searchFilters.length ) {
   return data?.filter((deal) => {
     return parseJSON(deal?.tags)?.some((tag) => searchFilters?.includes(tag));
   });
  }
    return data;
  };

  addOrRemoveFilter = (filter) => {
    let { searchFilters } = this.state;
    let isChecked = searchFilters?.includes(filter);
    if (isChecked) {
      let newFilters = searchFilters?.filter((item) => item !== filter);
      this.setState({ searchFilters: newFilters }, () => this.search());
    } else {
      this.setState({ searchFilters: [...searchFilters, filter] },()=>this.search());
    }
  };

  render() {
    const {placeholder, withFilters } = this.props;
    let { queryText, showFilters } = this.state;

    return (
      <div>
        <div className="search-bar-root-container elevate-float">
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div className="search-area">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    marginRight: 10,
                    fontSize: "1.1rem",
                    color: "var(--app-accent-pink)",
                  }}
                />
                <Textbox
                  className="search-input"
                  containerStyle={{ display: "inline" }}
                  placeholder={placeholder || "Search for deals"}
                  value={queryText}
                  onChange={(e) => {
                    this.setState({ queryText: e.target.value }, () =>
                      this.search()
                    );
                  }}
                />
              </div>
              {withFilters && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifySelf: "flex-end",
                  }}
                >
                  <span
                    className="filter-btn"
                    onClick={() => {
                      this.setState({ showFilters: !showFilters });
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    <small>Filters</small>
                  </span>
                </div>
              )}
            </div>

            {showFilters && (
              <>
                <div className="filter-box-root">
                  <div className="filter-field-container">
                    <CheckboxGroup
                      data={this.props?.filters || []}
                      groupClassName="search-filter-checkboxes"
                      labelExtractor={(item) => item?.name}
                      onItemSelected={(existing, newItem) =>
                        this.addOrRemoveFilter(newItem)
                      }
                      valueExtractor={(item) => item?.name}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchBar
