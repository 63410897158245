import React from "react";
import {NoItems } from "@kehillahglobal/ui";
import DealCard from "../deal card/DealCard";


export default function DealsSearchResults(props) {
  return (
    <div className="user-deal-items-wrapper">
      {props?.results?.length > 0 ? (
        props?.results.map((item, index) => {
          return <DealCard inMyDeals={true} deal={item} key={index} />;
        })
      ) : (
        <div id="no-deals-container">
          <NoItems text="Records matching query not found" />
        </div>
      )}
    </div>
  );
}
