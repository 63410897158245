import React from "react";
import { useEffect, useState } from "react";
import ApiCallHandler from "../../../../shared/js/ApiCallHandler";
import URLS from "../../../../shared/js/urls";
import { Table, NoItems } from "@kehillahglobal/ui";
import {
  convertNumberToShortForm,
  getMeaningfulDate,
  getMyCurrencyInfo,
  getRate,
} from "../../../../shared/js/utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Processing from "../../../../shared/components/processing/Processing";

export default function DealRevenue(props) {
  const [revenueItems, setRevenueItems] = useState([]);
  const [loading, setLoading] = useState(false);
  let rates = useSelector((state) => state.exchangeRates);
  let user = useSelector((state) => state.userData);
  let params = useParams();

  useEffect(() => {
    setLoading(true);
    ApiCallHandler.send(URLS.LIST_DEAL_REVENUE, "POST", {
      deal: params?.dealID,
    }).then((res) => {
      setLoading(false);
      if (res && res?.success) {
        setRevenueItems(res?.data);
      }
    });
  }, [params?.dealID]);

  const convertMoney = (money, code) => {
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  const getUserCurrency = getMyCurrencyInfo(user?.currency);
  const prepareTableData = (revenueItems) => {
    let tableData = [];
    revenueItems.map((item, index) => {
      return tableData.push([
        <p>{index + 1}</p>,
        <p>{item?.income_type?.name}</p>,
        <p>
          {convertNumberToShortForm(parseFloat(item?.amount)?.toFixed(2))} (
          {getUserCurrency?.symbol}{" "}
          {convertNumberToShortForm(
            parseFloat(convertMoney(item?.amount, currency?.code)?.toFixed(2))
          )}
          )
        </p>,
        <p>{getMeaningfulDate(item?.transaction_date?.split("T")[0])}</p>,
        <p>{item?.payment_mode}</p>,
      ]);
    });

    return tableData;
  };

  const currency = getMyCurrencyInfo(props?.deal?.enterprise?.currency);
  return (
    <div>
      {loading ? (
        <Processing text={"Loading..."} />
      ) : (
        <div style={{ borderRadius: 10 }}>
          {revenueItems?.length ? (
            <Table
              columns={[
                "#",
                "Revenue Type",
                `Amount (${currency?.symbol})`,
                "Transaction Date",
                "Payment Mode",
              ]}
              data={prepareTableData(revenueItems)}
              hoverAnimation={false}
            />
          ) : (
            <NoItems text="No revenue added for this deal" />
          )}
        </div>
      )}
    </div>
  );
}
