import React, { Component } from "react";
import { FormGenerator } from "@kehillahglobal/ui";
import { getRandomStringKey } from "../../../../shared/js/utils";
import { isEmpty } from "../../../../shared/js/utils";

export default class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      key: null,
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if (!state.key) {
        return {
          data: props.data,
          key: getRandomStringKey(),
        };
      }
      return {
        data: props.data,
      };
    }
    return null;
  }
  render() {
    const getFieldValue = (field) => {
      let { data } = this.state;
      let value = !isEmpty(data) ? data[field] : "";
      return value;
    };
    const Fields = FormGenerator.Fields;
    return (
      <div key={this.state.key}>
        <FormGenerator
          subtitle=""
          title=""
          elevation={0}
          fields={[
            {
              fieldType: Fields.INPUT,
              label: "Account Name",
              name: "account_name",
              placeholder: "Account name",
              required: true,
              value: getFieldValue("account_name"),
            },
            {
              fieldType: Fields.INPUT,
              name: "holder_address",
              label: "Address",
              placeholder: "Enter Account Holder's Address",
              required: true,
              value: getFieldValue("holder_address"),
            },
          ]}
          hideSubmitBtn={true}
          onStateChange={
            this.props.onStateChange ? this.props.onStateChange : null
          }
        />
      </div>
    );
  }
}
