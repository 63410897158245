import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../deal details/tab-items/user-deals-details.css";

import { withRouter } from "react-router-dom";
import Title from "../../../components/title/Title";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import {
  toggleToastAction,
  toggleModalAction,
  getAllDealsAction,
  getUserInvitationsAction,
  toggleOverlayAction,
} from "../../../store/actions/actions";
import URLS from "../../../shared/js/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "@kehillahglobal/ui";
import { CLIENT_PREFIX, getMyCurrencyInfo, getRate } from '../../../shared/js/utils';
import Processing from "../../../shared/components/processing/Processing";
import BackButton from "../../../shared/components/back-button/BackButton";

class InvitationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      invitation:{}
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { invitations } = props;
    let invitationId = props?.match?.params?.id;
    if(invitationId){
      let invitation = invitations?.find(invitation => invitation.id === invitationId);
      return {
        invitation
      }
    }
    return null

  }

  RESPONSE_TYPES = {
    ACCEPT: "ACCEPT",
    DECLINE: "DECLINE",
  };

  onAcceptInvitation = async (invitation) => {
    this.props.toggleLoader({
      show: true,
      component: <Spinner variation={"TwoHalfCirclesType"} />,
    });

    const dataToSend = {
      shares: invitation?.shares,
      deal_id: invitation?.deal.id,
      deal_invitation_id: invitation?.id,
      action: "CREATE_USER_AND_PARTNERSHIP",
    };
    let res = await ApiCallHandler.send(
      URLS.ACCEPT_INVITATION_PATH,
      "POST",
      dataToSend
    );

    if (res) {
      this.props.toggleLoader({ show: false });
      this.showResponse(res);
    }
  };

  onDeclineInvitation = (invitation) => {
    this.props.toggleLoader({
      show: true,
      component: <Spinner variation={"TwoHalfCirclesType"} />,
    });
    const dataToSend = {
      id: invitation?.id,
      has_accepted: "False",
      has_responded: "True",
      is_archived: "True",
    };
    ApiCallHandler.send(
      URLS.UPDATE_DEAL_INVITATION,
      "POST",
      dataToSend
    ).then((res) => {
      if (res) {
        this.props.toggleLoader({ show: false });
        this.showResponse(res, this.RESPONSE_TYPES.DECLINE);
      }
    });
  };

  showResponse = (res, type = this.RESPONSE_TYPES.ACCEPT) => {
    if (res && res.success) {
      this.props.getDeals();
      this.props.getInvitations();
      this.props.history.push(CLIENT_PREFIX+"/deals");
      this.props.toggleToast({
        show: true,
        message: `${
          type === this.RESPONSE_TYPES.DECLINE ? "Declined" : "Acceptance"
        } Successful`,
        type: "success",
      });
    } else {
      this.props.toggleToast({
        showToast: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };

  showConfirmationModal = (item, type) => {
    this.props.toggleModal({
      show: true,
      props: {
        title: `Invitation Acceptance Confirmation`,
        children: (
          <div style={{ padding: "10px 15px" }}>
            Do you wish to{" "}
            {type === this.RESPONSE_TYPES.ACCEPT ? "accept" : "decline"} this
            deal invitation from
            {item?.deal?.enterprise?.name} ?
          </div>
        ),
        themeColor: "var(--app-theme)",
        cancel: true,
        okay: {
          text: "Yes",
          function: () =>
            type === this.RESPONSE_TYPES.ACCEPT
              ? this.onAcceptInvitation(item)
              : this.onDeclineInvitation(item),
        },
      },
    });
  };
  convertMoney = (money, code) => {
    let { rates } = this.props;
    let finalAmount = money / getRate(code, rates);
    return finalAmount;
  };
  render() {
    let { user} = this.props;
    let { invitation } = this.state;
    let enterpriseCurrency = getMyCurrencyInfo(invitation?.deal?.enterprise?.currency );
    let userCurrency = getMyCurrencyInfo(user?.currency );

    return invitation?.id ? (
      <div style={{ width: "95%", margin: "auto", position: "relative" }}>
        <BackButton />
        <div className="user-deal-details-container">
          <div className="comp-and-inv-info-container">
            <div className="comp-logo-name-container">
              <img
                src={
                  invitation?.deal?.enterprise?.logo ||
                  "https://i.pravatar.cc/300"
                }
                alt="company logo"
              />
              <div>
                <span>Invitation From </span>
                <h3>{invitation?.deal?.enterprise?.name}</h3>
              </div>
            </div>

            <div id="inv-info-container" style={{ marginTop: "2rem" }}>
              <Title>{invitation?.deal?.name}</Title>
              <div
                style={{ cursor: "pointer" }}
                dangerouslySetInnerHTML={{
                  __html: invitation?.deal?.about,
                }}
              ></div>
            </div>
          </div>

          <div className="inv-and-docs-container">
            <div className="elevate-float inv-detail-container">
              <p id="inv-detail-header">DEAL DETAILS</p>

              <div id="info-stats-container">
                <span>Closing Date</span>
                <p>{invitation?.deal?.close_date}</p>
              </div>
              <div id="info-stats-container">
                <span>Runs For</span>
                <p>
                  {
                    invitation?.deal
                      ?.deal_running_period_in_months ?  invitation?.deal
                      ?.deal_running_period_in_months + " Months": "Not set"
                  }
                </p>
              </div>
              <div id="info-stats-container">
                <span>Total Shares</span>
                <p>{invitation?.deal?.shares || 0}</p>
              </div>
              <div id="info-stats-container">
                <span>Allocated Shares</span>
                <p>{invitation?.shares || 0}</p>
              </div>
              <div id="info-stats-container">
                <span>Price Per Share</span>
                <p>
                  {enterpriseCurrency?.symbol}{" "}
                  {invitation?.deal?.price_per_share}(
                  {userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    invitation?.deal?.price_per_share || 0,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div id="info-stats-container">
                <span>Admin charges</span>
                <p>{invitation?.deal?.admin_fee || 0}% </p>
              </div>
              <div id="info-stats-container">
                <span>Management Charges</span>
                <p>
                  {enterpriseCurrency?.symbol}{" "}
                  {invitation?.deal?.mgmt_fee || 0} ({userCurrency?.symbol}{" "}
                  {this.convertMoney(
                    invitation?.deal?.mgmt_fee ||0,
                    enterpriseCurrency?.code
                  )?.toFixed(2)}
                  )
                </p>
              </div>
              <div id="info-stats-container">
                <span>Carry</span>
                <p>{invitation?.deal?.carry || 0} %</p>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="elevate-float accept-item response-btns"
                onClick={() =>
                  this.showConfirmationModal(
                    invitation,
                    this.RESPONSE_TYPES.ACCEPT
                  )
                }
                style={{ marginRight: "15px" }}
              >
                <FontAwesomeIcon icon={faCheck} />
                <p>Accept </p>
              </div>
              <div
                className="elevate-float reject response-btns"
                onClick={() =>
                  this.showConfirmationModal(
                    invitation,
                    this.RESPONSE_TYPES.DECLINE
                  )
                }
              >
                <FontAwesomeIcon icon={faTimes} />
                <p>Decline</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ): <Processing text={"Please wait. Loading invitation data.."} />;
  }
}
const mapStateToProps = (state) => {
  return {
    invitations: state.invitations,
    rates:state.exchangeRates,
    user:state.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDeals: getAllDealsAction,
      toggleToast: toggleToastAction,
      toggleModal: toggleModalAction,
      getInvitations: getUserInvitationsAction,
      toggleLoader:toggleOverlayAction
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvitationDetail)
);

