import {
  faCog,
  faDatabase,
  faMoneyBillAlt,
  faNewspaper,
  faPiggyBank,
  faSignOutAlt,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import { getActiveClientID } from "../../shared/js/utils";

let clientID = getActiveClientID();

export const MENU = [
  {
    groupName: "Manage",
    children: [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: faDatabase,
        link: `/client/${clientID}/dashboard`,
      },
      {
        id: "deals",
        name: "Deals",
        icon: faPiggyBank,
        link: `/client/${clientID}/deals`,
      },
      {
        id: "Feeds",
        name: "Feeds",
        icon: faNewspaper,
        link: `/client/${clientID}/deals/feeds`,
      },
      {
        id: "distributions",
        name: "Distributions",
        icon: faMoneyBillAlt,
        link: `/client/${clientID}/deals/distributions`,
      },
    ],
  },
];

export const BOTTOM_MENU = [
  {
    id: "wallets",
    name: "Wallets",
    icon: faWallet,
    link: `/client/${clientID}/wallets`,
  },
  {
    id: "settings",
    name: "Settings",
    icon: faCog,
    link: `/client/${clientID}/settings`,
  },
  {
    id: "sign-out",
    name: "Sign Out",
    icon: faSignOutAlt,
    onClick: () => {
      const iAmSureIWantToLogOut = window.confirm(
        "Are you sure you want to sign out?"
      );
      if (iAmSureIWantToLogOut) {
        ApiCallHandler.send(URLS.LOGOUT, "POST", {}).then((res) => {
          if (res.success) {
            window.location.href = "/";
          }
        });
      }
    },
  },
];

export const BAR_DATA = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Monthly Earnings",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: ["green", "green", "green", "green", "green", "green"],
      borderWidth: 1,
    },
  ],
};
