import React, { Component } from "react";
import { connect } from "react-redux";
import "./user-deals-details.css";
import Title from "../../../../components/title/Title";
import { withRouter } from "react-router-dom";
import  {Shimmer} from '@kehillahglobal/ui';
import { getMyCurrencyInfo } from "../../../../shared/js/utils";
class DealDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocs: false,
        deal:{}
    }
  }
  static getDerivedStateFromProps(props, state) {
    let {dealID} = props?.match?.params;
    if(dealID){
      let deal = props?.deals?.find(item =>item?.deal.id === dealID);
      return {
        deal
      }
    }
    return 
  }
  render() {
    let { deal } = this.state;
    let currency = getMyCurrencyInfo(
      deal?.deal?.enterprise?.currency
    );
    return (
      <div className="user-deal-details-container">
        <div className="comp-and-inv-info-container">
          <div className="comp-logo-name-container">
            {deal?.deal?.enterprise?.logo ? (
              <img src={deal?.deal?.enterprise?.logo} alt="company logo" />
            ) : (
              <Shimmer height={9} radius={3} width={10} />
            )}

            <div>
              <span>Deal From </span>
              <h3>{deal?.deal?.enterprise?.name}</h3>
            </div>
          </div>
          <div id="inv-info-container" style={{ marginTop: "2rem" }}>
            <Title>{deal?.deal?.name}</Title>

            <div
              style={{ cursor: "pointer" }}
              className="deal-inv-info-container"
              dangerouslySetInnerHTML={{
                __html: deal?.deal?.about,
              }}
            ></div>
          </div>
        </div>

        <div className="inv-and-docs-container">
          <div className="elevate-float inv-detail-container">
            <p id="inv-detail-header">DEAL DETAILS</p>

            <div id="info-stats-container">
              <span>Closing Date</span>
              <p>{deal?.deal?.close_date || "Not set"}</p>
            </div>
            <div id="info-stats-container">
              <span>Runs For</span>
              <p>
                {deal?.deal?.deal_running_period_in_months
                  ? deal?.deal?.deal_running_period_in_months + " months"
                  : "Not set"}{" "}
              </p>
            </div>
            <div id="info-stats-container">
              <span>Total Shares</span>
              <p>{deal?.deal?.shares || 0}</p>
            </div>
            <div id="info-stats-container">
              <span>Allocated Shares</span>
              <p>{deal?.shares || 0}</p>
            </div>

            <div id="info-stats-container">
              <span>Price Per Share</span>
              <p>
                {currency?.symbol} {deal?.deal?.price_per_share || 0}
              </p>
            </div>
            <div id="info-stats-container">
              <span>Admin charges</span>
              <p>{deal?.deal?.admin_fee || 0}% </p>
            </div>
            <div id="info-stats-container">
              <span>Management Charges</span>
              <p>
                {currency?.symbol} {deal?.deal?.mgmt_fee || 0}
              </p>
            </div>
            <div id="info-stats-container">
              <span>Carry</span>
              <p>{deal?.deal?.carry || 0} %</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    deals: state.myDeals,
    user: state.userData,
  };
};

export default withRouter(connect(mapStateToProps, null)(DealDetails));
