import React from "react";
import PropTypes from "prop-types";
import "./Header.css";
function Header(props) {
  const { title, subtitle, children, style } = props;
  return (
    <div className="everyday-flex title-box" style={style}>
      <h1>{title}</h1>
      <p>{subtitle}</p>
      {children}
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  style: PropTypes.object,
};

Header.defaultProps = {
  style: {},
  title: "Welcome Boss,",
  subtitle:
    " Is this just real life, is this just fantasy. Landslide, no mistake from reality...",
};
export default Header;
