import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faEllipsisV,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../../../components/header/Header";
import {
  archiveMutationAction,
  createMutationAction,
  editMutationAction,
  toggleModalAction,
} from "../../../store/actions/actions";
import { FormGenerator,NoItems } from "@kehillahglobal/ui";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { toggleToastAction } from "../../../store/actions/actions";
import Title from "../../../components/title/Title";
import { removeCountriesWithEmptyCurrency, validatePhoneNumber, getMyCurrencyInfo, onCountryOrCurrencySearch, isEmpty, getSupportedCountriesAndCurrencies } from "../../../shared/js/utils";
import { MOBILE_NETWORKS, MOBILE_WALLET_COLORS } from "./mobileMoneyWalletConstants";
import { phoneNumberValidator } from "../../../shared/js/formFieldValidators";
class MobileWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      showDelete: false,
      toDeleteId: "",
      errorMsg: "",
    };
  }



  getRandomColorScheme = (index) => {
    return MOBILE_WALLET_COLORS[index];
  };
  showMobileWallets = (wallet, index) => {
    let color = this.getRandomColorScheme(index);
    let currency = getMyCurrencyInfo(wallet?.currency);
    return (
      <div
        id="bank-item-container"
        className="elevate-float mobile-money-wallet-card-container"
      >
        <div
          className={`mobile-icon-container wallet-icon-container`}
          style={{ backgroundColor: color }}
        >
          <FontAwesomeIcon icon={faBroadcastTower} />
        </div>

        <div id="mobile-money-wallet-info-container">
          <div>
            <h3>{wallet?.registered_name?.toUpperCase()}</h3>
            <p style={{ marginTop: 7 }}>
              {wallet?.country}- in ({currency?.code})
            </p>
            <p id="account-number">
              {wallet?.phone_number} - {wallet?.network}{" "}
            </p>
          </div>
          {this.state.showDelete && wallet?.id === this.state.toDeleteId ? (
            <div className="delete-container elevate-float">
              <div onClick={() => this.handleAdd(wallet)}>
                <FontAwesomeIcon icon={faPen} /> Edit Wallet info
              </div>
              <div onClick={() => this.handleDelete(wallet)}>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete Wallet
              </div>
            </div>
          ) : null}

          <div
            className="more-container"
            onClick={() => this.showDeleteItem(wallet.id)}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        </div>
      </div>
    );
  };

  close = () => {
    this.props.toggleModal({
      show: false,
    });
  };
  showDeleteItem = (id = null) => {
    let { showDelete } = this.state;
    if (showDelete) {
      this.setState({ showDelete: false, toDeleteId: null });
    } else {
      this.setState({ showDelete: true, toDeleteId: id });
    }
  };

  updateRedux = (isEdit, data) => {
    let { wallets, createRecord, editRecord } = this.props;
    if (!isEdit) {
      createRecord(wallets, data);
    } else {
      editRecord(wallets, data[0]);
    }
  };
  handleSubmit = async (data, reset, isEdit) => {
    let res;
    if (!validatePhoneNumber(data?.phone_number)) {
      this.close();
      this.props.toggleToast({
        show: true,
        message: "Invalid phone number",
        type: "failure",
      });
      return;
    }

    this.setState({ errorMsg: "" });
    data.currency = `${data?.currency?.name}-${data?.currency?.currency?.name}`;
    data.country = data?.country?.name;

    if (!isEdit) {
      let { id, ...rest } = data;
      res = await ApiCallHandler.send(URLS.CREATE_MOBILE_WALLETS, "POST", rest);
    } else {
      res = await ApiCallHandler.send(URLS.UPDATE_MOBILE_WALLETS, "POST", data);
    }
    if (res && res.success) {
      this.close();
      this.props.toggleToast({
        show: true,
        message: !isEdit
          ? "Mobile Wallet created successfully"
          : "Mobile Wallet Updated Successfully",
        type: "success",
      });
      this.updateRedux(isEdit, res?.data);
      reset();
    } else {
      this.props.toggleToast({
        show: true,
        message: "An error occurred. Try again",
        type: "failure",
      });
    }
  };
  handleDelete = (item) => {
    let { wallets } = this.props;
    if (wallets?.length < 1) return;
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: true,
      props: {
        title: `Delete "${item?.registered_name}"`,
        children: `Are you sure you want to delete "${item?.registered_name}"`,
        themeColor: "maroon",
        cancel: true,
        okay: {
          text: "Yes",
          function: () => {
            ApiCallHandler.send(URLS.ARCHIVE_MOBILE_WALLETS, "POST", {
              id: item?.id,
            }).then(() => {
              this.props.archiveRecord(wallets, item?.id);
            });
          },
        },
      },
    });
  };
  extractCountry = (country) => {
    if (!country) return {};
    let _country = country?.split("-");
    return removeCountriesWithEmptyCurrency().find(
      (item) => item?.name === _country[0]
    );
  };

  form = (editData = null) => {
    let { userData } = this.props;
    const Fields  =  FormGenerator.Fields
    return (
      <>
        <p>{this.state.errorMsg}</p>
        <FormGenerator
          elevation={0}
          fields={[
            {
              fieldType: Fields.INPUT,
              label: "Registered Name",
              name: "registered_name",
              placeholder: "Registered name",
              required: true,
              value: editData ? editData.registered_name : "",
            },
            {
              data: getSupportedCountriesAndCurrencies(),
              fieldType: Fields.DROPDOWN,
              type: "full",
              name: "country",
              label: "Country",
              placeholder: "Select Country",
              required: true,
              labelExtractor: (item) => item.name?.toUpperCase(),
              valueExtractor: (item) => item.name,
              onSearch: (value) => onCountryOrCurrencySearch(value),
              value: editData ? this.extractCountry(editData?.country) : "",
            },
            {
              data: getSupportedCountriesAndCurrencies(),
              fieldType: Fields.DROPDOWN,
              type: "full",
              name: "currency",
              label: "Currency",
              placeholder: "Select Currency",
              required: true,
              valueExtractor: (item) => item?.currency?.name,
              labelExtractor: (item) =>
                `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                  item?.currency?.symbol
                })`,
              onSearch: (value) => onCountryOrCurrencySearch(value),
              value: editData ? this.extractCountry(editData?.currency) : "",
            },
            {
              data: MOBILE_NETWORKS.GHANA,
              fieldType: Fields.DROPDOWN,
              label: "Network",
              name: "network",
              placeholder: "Network",
              required: true,
              value: editData ? editData.network : "",
              type: "full",
            },
            {
              fieldType: Fields.INPUT,
              name: "phone_number",
              label: "Phone Number",
              placeholder: "+233500000000",
              required: true,
              value: editData ? editData.phone_number : "",
              validator:(phone)=> phoneNumberValidator(phone)
            },
          ]}
          onSubmit={(data, reset) => {
            data = {
              ...data,
              id: editData ? editData.id : null,
              user: userData?.id,
            };
            this.handleSubmit(data, reset, editData ? true : false);
          }}
          formSubmitBtnText={editData ? "Update" : "Create"}
          subtitle=""
          title=""
        />
      </>
    );
  };
  handleAdd = (editData = null) => {
    this.setState({ showDelete: false });
    this.props.toggleModal({
      show: this.state.showModal,
      props: {
        title: `${editData ? "Edit" : "Add New"} Wallet`,
        children: this.form(editData),
        themeColor: "var(--app-theme)",
        cancel: false,
        size: "medium",
      },
    });
  };
  render() {
    let { wallets } = this.props;
    return (
      <div id="mobile-wallet-wrapper">
        <div id="header-txt-and-add-container">
          <div onClick={() => this.handleAdd()} style={{ marginLeft: "20px" }}>
            <Title className="text-toggler">
              <FontAwesomeIcon icon={faPlus} /> New Mobile Money Wallet
            </Title>
          </div>
          <Header title="Mobile wallets" subtitle="" />
        </div>

        <div id="mobile-wallet-list-container">
          {!isEmpty(wallets) ? (
            wallets?.map((wallet, index) => this.showMobileWallets(wallet, index))
          ) : (
            <div>
              <NoItems />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    modalProps: state.modalProps,
    toastProps: state.toastProps,
    wallets: state.mobileMoneyWallets,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      toggleToast: toggleToastAction,
      archiveRecord: (current_data, data_to_delete_id) =>
        dispatch(
          archiveMutationAction("MOBILE_WALLETS", current_data, data_to_delete_id)
        ),
      createRecord: (current_data, new_data) =>
        dispatch(createMutationAction("MOBILE_WALLETS", current_data, new_data)),
      editRecord: (current_data, new_data) =>
        dispatch(editMutationAction("MOBILE_WALLETS", current_data, new_data)),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileWallet);
