import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {TabView} from "@kehillahglobal/ui";
import { getPartnershipStatsAction, toggleModalAction } from "../../../store/actions/actions";
import DealDetails from './tab-items/DealDetails';
import DealAssets from './tab-items/DealAssets';
import DealDocuments from './tab-items/DealDocuments';
import AcquisitionStatus from "./tab-items/AcquisitionStatus";
import Expenses from './tab-items/Expenses';
import '../user-deals.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { capitalizeMe, convertNumberToShortForm, getMyCurrencyInfo, hasEmptyFields, parseJSON } from '../../../shared/js/utils';
import DealDetailFeeds from "./tab-items/DealDetailFeeds";
import DealRevenue from "./tab-items/Revenue";
import { DEAL_ACQUISITION_STATUS_TAB_ID, DEAL_ASSETS_TAB_ID, DEAL_DETAILS_TAB_KEY, DEAL_DOCUMENTS_TAB_ID, DEAL_EXPENSES_TAB_ID, DEAL_FEEDS_TAB_ID, DEAL_REVENUE_TAB_ID } from "./DealsTabIDs";
import BackButton from "../../../shared/components/back-button/BackButton";

class MyDealDetailWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changer: localStorage.dealTab
        ? localStorage?.dealTab 
        : DEAL_DETAILS_TAB_KEY,

        deal:{}
    };
  }
  async componentDidMount() {
    let {dealID,partnershipID}= this.props?.match?.params
    let deal = this.props.deals?.find((deal) => deal.deal.id === dealID);
    let res = await this.props.getPartnershipStats(partnershipID);
    this.setState({ stats: res?.payload, deal });
  }

  static getDerivedStateFromProps(props, state) {
      let { dealID } = props?.match?.params;
      let deal = props.deals?.find((deal) => deal.deal.id === dealID);
    if (props.partnershipStats) {
      return {
        stats: props.partnershipStats,
        deal,
      };
    }
    return;
  }

  TABS = [
    {
      name: "Details",
      id: DEAL_DETAILS_TAB_KEY,
      component: (
        <DealDetails switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
    {
      name: "Assets",
      id: DEAL_ASSETS_TAB_ID,
      component: (
        <DealAssets switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
    {
      name: "Documents",
      id: DEAL_DOCUMENTS_TAB_ID,
      component: (
        <DealDocuments switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },

    {
      name: "Realtime Update",
      id: DEAL_FEEDS_TAB_ID,
      component: (
        <DealDetailFeeds
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
    {
      name: "Revenue",
      id:DEAL_REVENUE_TAB_ID,
      component: (
        <DealRevenue
          switchTabs={(changer) => this.state.changer(changer)}
          deal={this.props.deal?.deal}
        />
      ),
    },

    {
      name: "Expenses",
      id: DEAL_EXPENSES_TAB_ID,
      component: (
        <Expenses
          switchTabs={(changer) => this.state.changer(changer)}
          deal={this.props.deal?.deal}
        />
      ),
    },
  ];

  PENDING_TABS = [
    {
      name: "Details",
      id: DEAL_DETAILS_TAB_KEY,
      component: (
        <DealDetails switchTabs={(changer) => this.state.changer(changer)} />
      ),
    },
    {
      name: "Acquisition Update",
      id: DEAL_ACQUISITION_STATUS_TAB_ID,
      component: (
        <AcquisitionStatus
          switchTabs={(changer) => this.state.changer(changer)}
        />
      ),
    },
  ];

  render() {
    const {  user } = this.props;
    let { stats, deal } = this.state;
    let is_approved =parseJSON(sessionStorage.is_approved)
    const userCurrency = getMyCurrencyInfo(user?.currency);
    return (
      <div style={{ width: "93%", margin: "auto" }}>
        <BackButton />
        {deal?.is_approved ? (
          <div className="flex-me stats-root">
            <div className="partnership-opp-name">
              {deal?.deal?.image && (
                <div className="deal-image-container">
                  <img
                    src={deal?.deal?.image}
                    alt="logo"
                    onClick={() => window.open(deal?.deal?.image, "_blank")}
                  />
                </div>
              )}
              <h1>
                <p>{capitalizeMe(deal?.deal?.name)}</p>
              </h1>
            </div>

            <div className="flex-item">
              <div
                className="partnership-opp-name opp-valuation space-me-right"
                style={{ flexDirection: "column" }}
              >
                <h6>Deal Valuation</h6>
                {!hasEmptyFields(stats) ? (
                  <h1>
                    {userCurrency?.symbol}{" "}
                    {convertNumberToShortForm(stats?.stake?.toFixed(2) || 0)}
                  </h1>
                ) : (
                  <h4>Computing....</h4>
                )}
              </div>
              <div className="partnership-opp-name partner-arrow space-me-right vanish">
                <h1>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </h1>
              </div>
              <div className="everyday-flex right-of-title-box">
                <h6>Total in Distributions</h6>
                {!hasEmptyFields(stats) ? (
                  <h1>
                    {userCurrency?.symbol}{" "}
                    {convertNumberToShortForm(
                      stats?.total_in_distributions?.toFixed(2)
                    )}
                  </h1>
                ) : (
                  <h4>Computing....</h4>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: "2rem" }}>
            <h1>
              <p>{deal?.deal?.name}</p>
            </h1>
          </div>
        )}

        {is_approved ? (
          <TabView
            data={this.TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            onMount={(changer) => this.setState({ changer })}
            defaultTab={this.state.changer}
          />
        ) : (
          <TabView
            data={this.PENDING_TABS}
            contentAreaStyle={{ paddingTop: 30 }}
            onMount={(changer) => this.setState({ changer })}
            defaultTab={DEAL_DETAILS_TAB_KEY}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deals: state.myDeals,
    user: state.userData,
    rates: state.exchangeRates,
    distributions: state.myDistributions,
    partnershipStats: state.partnershipStats,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleModal: toggleModalAction,
      getPartnershipStats: getPartnershipStatsAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyDealDetailWrapper);
