import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingPage from "../../shared/components/loading/LoadingPage";
import ApiCallHandler from "../../shared/js/ApiCallHandler";
import URLS from "../../shared/js/urls";
import { parseJSON } from "../../shared/js/utils";
import { updateUserInfoAction } from "../../store/actions/actions";

const Success = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    let decodedData = parseJSON(atob(window.location.href.split("entry=")[1]));
    const params = window.location.href.toString().split("||");
    ApiCallHandler.send(URLS.LOGIN, "POST", {
      idToken: decodedData?.tokenId,
    }).then((res) => {
      if (res?.success) {
        dispatch(updateUserInfoAction(res?.data));
        if (params && params[1]) {
          history.push(
            `/client/${res?.data?.id}/deal/response/action/${params[1]}`
          );
          return;
        }
        window.location.href = `/client/${res?.data?.id}/dashboard`;
      }
    });
  }, [dispatch, history]);
  return <LoadingPage text={"Preparing your account"} />;
};

export default Success;
