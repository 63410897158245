import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./FeedsCard.css";
import Title from "../../../components/title/Title";
import { withRouter } from "react-router-dom";
import { CLIENT_PREFIX, getFileType, getMeaningfulDate, isEmpty } from "../../../shared/js/utils";
import { toggleImagePreviewAction } from "../../../store/actions/actions";
import { IMAGE_TYPES, VIDEO_TYPES } from "../../../shared/js/Konstants";
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FeedsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newReport: {},
    };
  }


  getFileName = (file) => {
    if(!file) return ;
    let arr = file?.split("/");
    let last = arr?.[arr?.length - 1]?.split("?");
    return last[0];
  };
  showMedia = (file) => {
    if (!file) return;
    let type = getFileType(file);

    if (IMAGE_TYPES.includes(type))
      return (
        <div id="feed-card-body-images">
          <img
            src={file}
            alt="  "
            onClick={() => {
              this.props.toggleImagePreview({
                show: true,
                image: file,
              });
            }}
          />
        </div>
      );

    if (VIDEO_TYPES.includes(type))
      return (
        <div id="feed-card-body-images">
          <video src={file} controls />
        </div>
      );

    return (
      <div className="comment-file-container ">
        <div
          className="elevate-float file-wrapper"
          onClick={() => window.open(file, "_blank")}
        >
          <FontAwesomeIcon icon={faFileAlt} fontSize="7rem" color="maroon" />
        </div>
        <p
          style={{ color: "blue" }}
          onClick={() => window.open(file, "_blank")}
        >
          {this.getFileName(file)}
        </p>
      </div>
    );
  };

  truncateTextBody = (text) => {
    if (isEmpty(text)) return "";
    let toShow = text?.split("</p>")[0];
    if (toShow.length > 300) return toShow?.substr(0, 300) + "...";
    return toShow;
  };

  viewDetails = (feed) => {
    this.props.history.push(CLIENT_PREFIX+`/deals/feeds/${feed?.id}`);
  };
  render() {
    let { feed } = this.props;
    return (
      <div className="feed-card-root elevate-float">
        <div
          id="feed-card-logo-and-name-container"
          onClick={() => this.viewDetails(feed)}
        >
          <div id="feed-card-logo-container">
            <img
              src={feed?.deal?.enterprise?.logo || "https://i.pravatar.cc/300"}
              alt="  "
            />
          </div>

          <div id="feed-card-opp-det">
            <h2>{feed?.deal?.name}</h2>
            <p>{feed?.deal?.enterprise?.name}</p>
            <small>{getMeaningfulDate(feed?.date)}</small>
          </div>
        </div>

        <div className="feed-card-body-container feed-card-body">
          <div
            id="feed-card-title-container"
            onClick={() => this.viewDetails(feed)}
          >
            <Title>{feed?.title || ""}</Title>
          </div>

          <div className="feed-card-body-container">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.viewDetails(feed)}
              className="tweet-card-content optimum-padding-right-left"
              dangerouslySetInnerHTML={{
                __html: this.truncateTextBody(feed?.body),
              }}
            ></div>
          </div>
          {this.showMedia(feed?.file)}
        </div>
        {/* Will be needed when feed commenting is added */}

        {/* <div id="feed-card-footer-container">
          <div
            className="elevate-float comment-btn-container"
            onClick={() => alert("hello")}
          >
            <FontAwesomeIcon icon={faComment} />
            <span>Comment </span>
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    toggleImagePreview:toggleImagePreviewAction
  }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedsCard)
);
