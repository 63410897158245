import React from "react";
import { FormGenerator } from "@kehillahglobal/ui";
import {
  onCountryOrCurrencySearch,
  removeCountriesWithEmptyCurrency,
} from "../../../shared/js/utils";
import "./style.css";
import ApiCallHandler from "../../../shared/js/ApiCallHandler";
import URLS from "../../../shared/js/urls";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateUserInfoAction } from "../../../store/actions/actions";
import { useHistory } from "react-router-dom";

export default function CountryAndCurrencySelection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const invitationId = props?.history?.location?.state?.invitationId;
  let user = useSelector((state) => state.userData);
  const onSubmit = (data, reset) => {
    let toSend = {};
    toSend.country = data?.country?.name;
    toSend.currency = `${data?.currency?.name}-${data?.currency?.currency?.name}`;
    toSend.id = user?.id;

    ApiCallHandler.send(URLS.UPDATE_USERS, "POST", toSend).then((res) => {
       const { data } = res;
       const [user] = data || {};
      dispatch(updateUserInfoAction(user));
      reset();
      if (user?.has_accepted_tcs === true) {
        history.push(`/client/${user?.id}/dashboard`);
      } else {
        if (invitationId) {
          history.push({
            pathname: "/terms-and-conditions",
            state: {
              invitationId: invitationId,
            },
          });
        } else {
          history.push("/terms-and-conditions");
        }
      }
    });
  };

  return (
    <div className="country-and-currency-root">
      <div className="elevate-float country-form-container">
        <div className="country-currency-notice">
          <h3>NOTE </h3>
          <p>
            The Information You provide here can't be changed. Make sure to
            choose the correct one.
          </p>
        </div>
        <FormGenerator
          elevation={0}
          fields={[
            {
              data: removeCountriesWithEmptyCurrency(),
              fieldType: FormGenerator.Fields.DROPDOWN,
              type: "full",
              name: "country",
              label: "Country",
              placeholder: "Select Country",
              required: true,
              labelExtractor: (item) => item.name?.toUpperCase(),
              valueExtractor: (item) => item.name,
              onSearch: (value) => onCountryOrCurrencySearch(value),
            },
            {
              data: removeCountriesWithEmptyCurrency(),
              fieldType: FormGenerator.Fields.DROPDOWN,
              type: "full",
              name: "currency",
              label: "Currency",
              placeholder: "Select Currency",
              required: true,
              labelExtractor: (item) =>
                `${item.name?.toUpperCase()} - ${item?.currency?.name?.toUpperCase()} (${
                  item?.currency?.symbol
                })`,
              onSearch: (value) => onCountryOrCurrencySearch(value),
            },
          ]}
          onSubmit={onSubmit}
          formSubmitBtnText="Save Information"
          subtitle=""
          title=""
        />
      </div>
    </div>
  );
}
